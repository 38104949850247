import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API } from "../../API/Api";
import "./Welcomepage.css";

export default function WelcomePage() {
  const { uid } = useParams();
  const name = sessionStorage.getItem("Name");
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [getAllData, setGetAllData] = useState({
    uid: "User Id",
    email: "Email Address",
    mobile: "mobile",
  });

  const Welcome_API = async () => {
    try {
      setSpinner(true);
      let res = await API.get(`welcomeLetter?uid=${uid}`);
      res = res.data.data[0][0];
      if (res !== undefined) {
        setGetAllData(res);
        setBtnDisable(false);
      } else {
        Welcome_API();
      }
    } catch (error) {
      console.error("WelcomePage API Error:", error);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    Welcome_API();
    const storedPassword = sessionStorage.getItem("Password");
    setPassword(storedPassword || "*****");
  }, []);

  return (
    <div className="wrapper_main">
      <div className="wrapper">
        <div className="main1">
          <div className="main">
            <h2 style={{ textAlign: "center", color: "red" }}>
              WELCOME TO Club99
            </h2>
            <h3 style={{ textAlign: "center", color: "#ff8400" }}>
              WELCOME LETTER
            </h3>
            <h4>
              TO Mr./ Mrs./ Miss, <span style={{ color: "red" }}>{name}</span>{" "}
            </h4>
            <p style={{ color: "white" }}>
              THANK YOU FOR THE REGISTRATION WITH Club99. YOUR CORDIAL
              ASSOCIATION IS WELCOME IN THE BUSINESS FAMILY. WISH YOU A BRIGHT
              FUTURE FOR GROWTH.
            </p>
            <br />
            <h5 style={{ color: "red" }}>NAME: {name} </h5>
            <h5 style={{ color: "red" }}>USER ID: {getAllData.uid}</h5>
            <h5 style={{ color: "red" }}>PASSWORD: {password}</h5>
            <h5 style={{ color: "red" }}>EMAIL: {getAllData.email} </h5>
            <h5 style={{ color: "red" }}>MOBILE: {getAllData.mobile} </h5>
            <br />
            <p style={{ width: "100%", float: "left", textAlign: "center" }}>
            {spinner ? (
                <span>Loading...</span>
              ) : (
                <Link
                  to="/login"
                  type="button"
                  // disabled={btnDisable}
                  style={{
                    color: "#0d0d0d",
                    textDecoration: "none",
                    backgroundColor: "#fedc00",
                    borderRadius: 10,
                    padding: "6px 14px",
                  }}>
                  Go To Login
                </Link>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
