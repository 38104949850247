import React, { useState } from "react";
import a from "../../Media/logoclub99.png";
import { API } from "../../API/Api";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

export default function ForgotPassword() {
  const [inputValue, setInputValue] = useState("");
  const Navigate = useNavigate();

  const CheckNumber = async (e) => {
    let { value } = e.target;

    const newValue = value.replace(/[^0-9]/gi, "");
    if (newValue === "") {
      setInputValue("");
    } else {
      setInputValue(newValue);
    }
  };

  const schema = yup.object().shape({
    uid: yup
      .string()
      .required("Id is required")
      .min(6, "User ID should be at least 6 characters")
      .max(8, "Invalid User ID "),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const ForgotPassword = async () => {
    let res = await API.post("/forgetpassword", {
      uid: inputValue,
    });
    console.log("forgotP-->", res);
    if (
      res.data.data ==
      "Password has been successfully sent to registered email Id"
    ) {
      toast.success("Password Send Successfully !!");
      Navigate("/Login");
      return true;
    } else {
      toast.error(`Invalid User Id`);
    }
  };

  return (
    <div className="background-img">
      <div className="d-flex justify-content-center align-items-center mt-5">
        <div className="card wallet-card  mt-5">
          <div className="d-flex justify-content-center mt-4">
            <img className="img-fluid img-div" src={a} alt="a" />
          </div>

          <div className="px-4">
            <form onSubmit={handleSubmit(ForgotPassword)}>
              <div className="mb-3">
                <label for="exampleInputEmail1" className="form-label">
                  Enter User ID
                </label>
                <TextField
                  fullWidth
                  type="text"
                  className="form-control"
                  id="outlined-required"
                  label="Enter User Id"
                  defaultValue=""
                  margin="normal"
                  {...register("uid", { required: true })}
                  value={inputValue}
                  maxLength={8}
                  onChange={(e) => {
                    CheckNumber(e);
                  }}
                />
                <p className="texterr">{errors.uid?.message}</p>
              </div>

              <div className="d-flex justify-content-center">
                <button type="submit" className="btn login-btn mt-2">
                  Send To Email
                </button>
              </div>
              <div className="bth d-flex justify-content-center mt-2">
                Back to{" "}
                <span>
                  <Link className="nav-link" to="/">
                    {" "}
                    Home
                  </Link>{" "}
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
