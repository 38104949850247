import React, { useEffect, useState } from "react";
import a from "../../Media/logoclub99.png";
import { Link, useNavigate } from "react-router-dom";
import { API } from "../../API/Api";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";

export default function Register() {
  let history = useNavigate();
  const [checkbox, setcheckbox] = useState(false);
  const [userId, setuserId] = useState(null);
  const [spinnerload, setspinnerload] = useState(false);
  const [getEmail, setgetEmail] = useState(null);
  const [checkreffarl, setcheckreffarl] = useState(false);
  // const [countries, setCountries] = useState([]);
  const [spinner, setspinner] = useState(false);
  const [RefID, setRefID] = useState(null);
  // const [emailError, setEmailError] = useState("");
  // const [mobileError, setMobileError] = useState("");
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [fname, setfname] = useState("");
  const [validation, setValidation] = useState({
    mob: "",
    email: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value.substring(0, 25) });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const schema = yup.object().shape({
    // sid: yup.string().required("Sponser id is required"),
    f_name: yup.string().required("User name is required"),
    email: yup
      .string()
      .email()
      .required("email required")
      .matches(/^(?!.*@[^,]*,)/),
    mob: yup
      .string()
      .required("Mobile Number is required")
      .min(10, "Mobile Number should be at least 10 characters")
      .max(12, "Mobile Number cannot exceed more than 12 characters"),
    otp: yup.string().required("OTP is required"),
    psw: yup
      .string()
      .required("Password is required")
      .min(4, "Password length should be at least 4 characters")
      .max(25, "Password cannot exceed more than 25 characters"),
    cpsw: yup
      .string()
      .required("Confirm Password is required")
      .min(4, "Password length should be at least 4 characters")
      .max(25, "Password cannot exceed more than 25 characters")
      .oneOf([yup.ref("psw")], "Passwords do not match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const getresponseId = async (e) => {
    let { value } = e.target;
    const newValue = value.replace(/[^0-9]/gi, "").substring(0, 8);
    if (newValue === "") {
      setuserId("");
      setRefID("");
    } else {
      setRefID(newValue);
      let res = await API.get(`/check_sponsorid?uid=${newValue}`);

      if (res.data.data.result === "Sponsor Id doesnot exists !!") {
        setuserId("Wrong sponser id");
      } else {
        // let { f_name } = res.data.data[0];
        setuserId(res.data.data.result);
      }
    }
  };

  const checkUserName = async (e) => {
    let { value } = e.target;

    const newValue = value.replace(/[^A-Z a-z]/g, "");

    if (newValue === "") {
      setfname("");
    } else {
      setfname(newValue);
    }
  };

  const onSubmitHandler = async (data) => {
    setspinnerload(true);

    try {
      let res = await API.post(
        "/registration",

        checkreffarl
          ? {
              sid: RefID,
              f_name: data.f_name,
              email: data.email,
              psw: data.psw,
              mob: data.mob,
              otp: data.otp,
            }
          : {
              sid: data.sid,
              f_name: data.f_name,
              email: data.email,
              psw: data.psw,
              mob: data.mob,
              otp: data.otp,
            }
      );
      //   console.log("res.data.data.result", res);

      if (res.data.data.result === "Successfully register...!!!") {
        sessionStorage.setItem("Name", data.f_name);
        sessionStorage.setItem("Password", data.psw);
        toast.success(`Successful`);
        let uid = res.data.data.uid;
        console.log("resuid", uid);
        history(`/welcomepage/${uid}`);

        // history(`/WelcomePage?userid=${uid}`);
      } else {
        toast.error(`${res.data.data.result}`);
        setspinnerload(false);
      }
      setspinnerload(false);
    } catch (error) {
      console.log("API ERROR", error);
    }
  };

  const emailVerification = async (Id) => {
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      console.log("Email_check", getEmail);
      if (!emailRegex.test(getEmail)) {
        toast.error("Please enter a valid email address.");
      } else {
        setspinner(true);
        let res = await API.post("/emailVerification", {
          email: getEmail,
        });
        if (res.data.data === "Successfull") {
          Id === "Resend"
            ? toast.success(`OTP send Successfull on this ${getEmail}  `)
            : toast.success(`OTP Resend Successfull on this ${getEmail}  `);
        }
        setspinner(false);
      }

      //   console.log("res", res);
    } catch (error) {
      console.log(error);
      setspinner(false);
    }
  };

  const ReferralAddress = async () => {
    try {
      let URL = window.location.href;
      let URL_ID = new URLSearchParams(window.location.search).get("userid");

      if (URL.includes("userid")) {
        setcheckreffarl(true);
        setRefID(URL_ID);
        let res = await API.get(`/check_sponsorid?uid=${URL_ID}`);

        if (res.data.data.result !== "Sponsor Id doesnot exists !!") {
          console.log("URL", res.data);
          let { result } = res.data.data;
          console.log("result", result);
          setuserId(result);
        } else {
          setuserId("Wrong sponser id");
        }
      } else {
      }
    } catch (e) {
      console.log("Erroe Whille Referral Fuction Call", e);
    }
  };
 
  useEffect(() => {
    ReferralAddress();
  }, []);

  return (
    <div className="background-img">
      <div className="d-flex justify-content-center align-items-center mt-5">
        <div className="mt-5">
          <div className="">
            <div className="mb-3">
              <div className="container h-100">
                <div className="row align-items-center justify-content-center h-100">
                  <div className="col-12">
                    <div className="row align-items-center">
                      <div className="col-lg-4"></div>
                      <div className="col-lg-5 ">
                        <div className="wallet-card">
                          <div className="d-flex justify-content-center mt-4">
                            <img
                              className="img-fluid img-div"
                              src="assets/img/logo/logo.gif"
                              alt="a"
                            />
                          </div>
                          <h2 className="mb-2 text-white">Sign Up</h2>
                          <p className="text-white">
                            Enter your personal details and start journey with
                            us.
                          </p>
                          <form onSubmit={handleSubmit(onSubmitHandler)}>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="floating-label form-group space">
                                  <input
                                    type="text"
                                    className="form-control "
                                    name="sponser_id"
                                    placeholder="Enter Sponsor Id"
                                    required="required"
                                    {...register("sid", { required: true })}
                                    value={RefID}
                                    onChange={(e) => {
                                      getresponseId(e);
                                    }}
                                  />
                                </div>
                                <p className="texterr">{errors.sid?.message}</p>
                              </div>
                              <div className="col-lg-6">
                                <div className="floating-label form-group">
                                  <input
                                    type="text"
                                    className="form-control floating-input"
                                    name="sponsername"
                                    placeholder="Sponsor Name"
                                    readOnly="true"
                                    value={userId}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="floating-label form-group space mtop">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="f_name"
                                    placeholder="User Name"
                                    maxLength={20}
                                    {...register("f_name", {
                                      required: true,
                                    })}
                                    value={fname}
                                    onChange={(e) => {
                                      checkUserName(e);
                                    }}
                                  />
                                </div>
                                <p className="texterr">
                                  {errors.f_name?.message}
                                </p>
                              </div>
                             
                              <div className="col-lg-6 ">
                                <div className="floating-label form-group mtop">
                                  <input
                                    type="number"
                                    className="floating-input form-control"
                                    placeholder="Mobile Number"
                                    maxLength={15}
                                    {...register("mob", { required: true })}
                                  />
                                </div>
                                <p className="texterr">{errors.mob?.message}</p>
                              </div>
                              <div className="col-lg-6">
                                <div className="floating-label form-group space">
                                  <input
                                    type="password"
                                    className="floating-input form-control"
                                    name="password"
                                    placeholder="Password"
                                    maxLength={25}
                                    value={values.password}
                                    {...register("psw", { required: true })}
                                    onChange={handleChange("password")}
                                  />
                                </div>
                                <p className="texterr">{errors.psw?.message}</p>
                              </div>
                              <div className="col-lg-6">
                                <div className="floating-label form-group">
                                  <input
                                    type="password"
                                    className="floating-input form-control"
                                    placeholder="Confirm Password"
                                    maxLength={25}
                                    // required="required"
                                    {...register("cpsw", { required: true })}
                                    onChange={handleChange("confirmPassword")}
                                    value={values.confirmPassword}
                                  />
                                </div>
                                <p className="texterr">
                                  {errors.cpsw?.message}
                                </p>
                              </div>
                              <div className="col-lg-6">
                                <div className="floating-label form-group">
                                  <input
                                    type="email"
                                    className="form-control input-log-cls"
                                    name="email"
                                    placeholder="example@gmail.com"
                                    maxLength={100}
                                    // required="required"
                                    {...register("email", { required: true })}
                                    // value={email}
                                    onChange={(e) =>
                                      setgetEmail(e.target.value)
                                    }
                                    // onChange={(e) => {
                                    //   handleValidation(e.target.value);
                                    // }}
                                  />
                                </div>
                                <p className="texterr">
                                  {errors.email?.message}
                                </p>
                              </div>
                              <>
                                <div className="col-md-6 " id="divotpbtn">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-success verifyotp space otpss"
                                    id="btnSendotp"
                                    onClick={() => emailVerification("OTP")}
                                  >
                                    {spinner
                                      ? "Loading..."
                                      : "Send OTP To Email Verification"}
                                  </button>
                                </div>
                                <div
                                  className="col-md-6 mb-1"
                                  id="divotptxt"
                                  // style={{ display: "none" }}
                                >
                                  <input
                                    autoComplete="off"
                                    className="form-control input-log-cls space mtop"
                                    id="otp"
                                    maxLength={10}
                                    name="otp"
                                    oninput="return numberValidation(this)"
                                    placeholder="Enter OTP"
                                    type="text"
                                    defaultValue=""
                                    {...register("otp", { required: true })}
                                  />
                                  <span
                                    className="field-validation-valid"
                                    data-valmsg-for="otp"
                                    data-valmsg-replace="true"
                                  />
                                  <p className="texterr">
                                    {errors.otp?.message}
                                  </p>
                                </div>
                                <div
                                  className="col-md-6 "
                                  id="divotpbtnresend"
                                  // style={{ display: "none" }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-success verifyotp otps space"
                                    id="btnSendotp1"
                                    onClick={() => emailVerification("Resend")}
                                  >
                                    Resend OTP To Email
                                  </button>
                                </div>
                              
                              </>
                              <div className="col-lg-12">
                                <div className="custom-control custom-checkbox mb-3">
                                  <FormGroup>
                                    <FormControlLabel
                                      control={<Checkbox color="success" />}
                                      checked={checkbox}
                                      onChange={(e) =>
                                        setcheckbox(e.target.checked)
                                      }
                                      required
                                      label="I Agree Your Terms and Conditions"
                                    />
                                  </FormGroup>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center">
                              <button
                                type="submit"
                                className="btn login-btn mt-2"
                              >
                                Register
                              </button>
                            </div>
                          </form>
                          <p className="mt-3">
                            Already have an Account{" "}
                            <Link to="/login" className="text-primary">
                              Log In
                            </Link>
                          </p>

                          <div className="bth d-flex justify-content-center mt-2">
                            Back to{" "}
                            <span>
                              <Link className="nav-link" to="/">
                                {" "}
                                &nbsp;Home
                              </Link>{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
