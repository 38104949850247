import React, { useState } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "./Components/Navbar/Navbar";
import Sidebar from "./Components/Sidebar/Sidebar";

import "./App.css";

import Home from "./Components/Home/Home";
import "react-toastify/dist/ReactToastify.css";
import Sport from "./Components/Sport/Sport";
import WalletSignIn from "./Components/WalletSignin/WalletSignIn";
import MyMarkeet from "./Components/MyMarkeet/MyMarkeet";
import BettingHistory from "./Components/BettingHistory/BettingHistory";
import FundsTransfer from "./Components/FundsTransfer/FundsTransfer";
import WithdrawHistory from "./Components/WithdrawHistory/WithdrawHistory";
import Login from "./Components/Login/Login";
// import WithDrawalUSDT from "./Components/WithdrawelUSDT/WithDrawalUSDT";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import WithDrawalUSDT from "./Components/WithdrawelUSDT/WithDrawalUSDT";
import AccountStatement from "./Components/AccountStatement/AccountStatement";
import TransactionHistory from "./Components/TransactionHistory/TransactionHistory";
import ProfitLoss from "./Components/ProfitLoss/ProfitLoss";
import CasinoResults from "./Components/CasinoResults/CasinoResults";
import LiveCasino from "./Components/LiveCasino/LiveCasino";
import Deposit from "./Components/Deposit/Deposit";
import InPlay from "./Components/InPlay/InPlay";
import ChangePassword from "./Components/ChangePassword/ChangePassword";
import ColorGame from "./Components/ColorGame/ColorGame";
import Aviator from "./Components/Aviator/Aviator";
import TeenPatti from "./Components/TeenPatti/TeenPatti";
import ThirtyTwoCards from "./Components/32Cards/ThirtyTwoCards";
import CardsB from "./Components/32cardsB/CardsB";
import DragonTiger from "./Components/DragonTiger/DragonTiger";
import Lucky from "./Components/Lucky7A/Lucky";
// import Footer from "./Components/Footer/Footer";
// import { Provider } from "react-redux";
// import { store } from "./Redux/Store/store";
import { ToastContainer, toast } from "react-toastify";
import Hero from "./Components/HomePage/Hero";
import Register from "./Components/Register/Register";
import WelcomePage from "./Components/Welcome Page/WelcomePage";
import MyReferrals from "./Components/My Referrals/MyReferrals";
import LevelDetails from "./Components/Level Details/LevelDetails";
import MyDownline from "./Components/My Downline/MyDownline";
import DirectLbusiness from "./Components/Direct L Business/DirectLbusiness";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import PrivateRoutes from "./utils/PrivateRoutes";
import FirstReferralBonus from "./Components/First Referral/FirstReferralBonus";
import FirstSelfDeposite from "./Components/First Self/FirstSelfDeposite";
import AllReferralBonus from "./Components/All Referral/AllReferralBonus";
import AllSelfDeposite from "./Components/All Self/AllSelfDeposite";
import LevelBonus from "./Components/Level Bonus/LevelBonus";
import DailySalary from "./Components/Daily Salary/DailySalary";
import WeeklyBonus from "./Components/Weekly Bonus/WeeklyBonus";
import ForgotPassword from "./Components/Forgot Password/ForgotPassword";
import ReceivedHistory from "./Components/FundRequest/ReceivedHistory";
import RequestHistory from "./Components/FundRequest/RequestHistory";
import WithDrawal from "./Components/WithdrawelUSDT/WithDrawal";
import DepositHistory from "./Components/Deposit/DepositHistory";
import Profile from "./Components/Profile/Profile";
import FundRequestINR from "./Components/FundRequest/FundRequestINR";
import FundRequestUSDT from "./Components/FundRequest/FundRequestUSDT";
import RequestHistoryUSDT from "./Components/FundRequest/RequestHistoryUSDT";
import FundRequestUPI from "./Components/FundRequest/FundRequestUPI";
import RequestHistoryUPI from "./Components/FundRequest/RequestHistoryUPI";

const App = () => {
  return (
    <div className="bg-black position-relative row app-row ">
      <BrowserRouter>
        <ToastContainer />

        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/WalletSignIn" element={<WalletSignIn />} />
          <Route path="/forgetPassword" element={<ForgotPassword />} />
          <Route path="/welcomepage/:uid" element={<WelcomePage />} />

          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard" element={<Home />} />
            <Route path="/InPlay" element={<InPlay />} />
            <Route path="/Sport" element={<Sport />} />
            <Route path="/MyMarkeet" element={<MyMarkeet />} />
            <Route path="/BettingHistory" element={<BettingHistory />} />
            <Route path="/FundsTransfer" element={<FundsTransfer />} />
            <Route path="/WithdrawalUSDT" element={<WithDrawalUSDT />} />
            <Route path="/WithdrawalHistory" element={<WithdrawHistory />} />
            <Route path="/AccountStatement" element={<AccountStatement />} />
            <Route
              path="/TransactionHistory"
              element={<TransactionHistory />}
            />
            <Route path="/ProfitLoss" element={<ProfitLoss />} />
            <Route path="/CasinoResults" element={<CasinoResults />} />
            <Route path="/Deposit" element={<Deposit />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
            <Route path="/LiveCasino" element={<LiveCasino />} />
            <Route path="/ColorGame" element={<ColorGame />} />
            <Route path="/Aviator" element={<Aviator />} />
            <Route path="/TeenPatti" element={<TeenPatti />} />
            <Route path="/32Cards" element={<ThirtyTwoCards />} />
            <Route path="/32CardsB" element={<CardsB />} />
            <Route path="/DragonTiger" element={<DragonTiger />} />
            <Route path="/Lucky7A" element={<Lucky />} />
            <Route path="/referrals" element={<MyReferrals />} />
            <Route path="/leveldetail" element={<LevelDetails />} />
            <Route path="/downline" element={<MyDownline />} />
            <Route path="/DLBusiness" element={<DirectLbusiness />} />
            {/* <Route path="/selfdeposite" element={<FirstReferralBonus />} /> */}
            <Route path="/referraldeposite" element={<FirstSelfDeposite />} />
            <Route path="/allreferral" element={<AllReferralBonus />} />
            {/* <Route path="/allself" element={<AllSelfDeposite />} /> */}
            <Route path="/levelbonus" element={<LevelBonus />} />
            <Route path="/salarydaily" element={<DailySalary />} />
            <Route path="/weeklybonus" element={<WeeklyBonus />} />
            <Route path="/FundRequestUSDT" element={<FundRequestUSDT/>} />
            <Route path="/FundRequestINR" element={<FundRequestINR/>} />
            <Route path="/FundRequestUPI" element={<FundRequestUPI/>} />
            <Route path="/FundRequestINR" element={<FundRequestINR/>} />
            <Route path="/FundReceivedHistory" element={<ReceivedHistory />} />
            
            <Route path="/RequestHistory" element={<RequestHistory />} />
            <Route path="/RequestHistoryUPI" element={<RequestHistoryUPI />} />
            <Route path="/FundRequestHistoryUSDT" element={<RequestHistoryUSDT />} />
            <Route path="/Withdrawal" element={<WithDrawal />} />
            <Route path="/DepositHistory" element={<DepositHistory />} />
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route path="*" element={<PageNotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
