import React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import club from "../../Media/logoclub99.png";
import { FaEnvelope, FaFacebookF, FaMapMarker, FaPinterestP } from "react-icons/fa";
import { GrReddit } from "react-icons/gr";
import { FaInstagram } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

export default function Header() {
  const expand = "lg";
  return (
    <>
      <Navbar
        collapseOnSelect
        key={expand}
        expand={expand}
        className="bgnavHXN mb-4"
      >
        <Container fluid>
          <Navbar.Brand href="/">
            <img
              className="w-100 h-100"
              src="assets/img/logo/logo.gif"
              alt="logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
            className="offbgHXN"
          >
            <Offcanvas.Header closeButton>
              <img
                className="img-fluid"
                src="assets/img/logo/logo.gif"
                alt="logo"
                style={{width: "110px"}}
              />
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end alignCNNtr flex-grow-1 pe-3">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="#Collection">Collection</Nav.Link>
                <Nav.Link href="#Explore">Explore</Nav.Link>
                <Nav.Link href="#nft">NFT</Nav.Link>
                <Nav.Link href="#News">News</Nav.Link>
                <Nav.Link href="#Team">Team</Nav.Link>
              </Nav>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link className="btn-theme1" href="#action1">
                  <Link to="/" className="btn btn-theme1 buttonH">
                    Wallet Connect
                  </Link>
                </Nav.Link>
                <Nav.Link className="btn-theme1" href="#action1">
                  <Link to="/Login" className="btn btn-theme1 buttonH">
                    Login
                  </Link>
                </Nav.Link>
                <Nav.Link href="#action2">
                  <Link to="/register" className="btn btn-theme1 buttonH">
                    Register
                  </Link>
                </Nav.Link>
              </Nav>
              <div className="social-links">
                <ul className="clearfix">
                  <li>
                    <a target="_blank" href="https://twitter.com/i/flow/login?redirect_after_login=%2FEagleBattle_">
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.facebook.com/people/Eagle-Battle/100083871291277/">
                      <FaFacebookF />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FaPinterestP />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/eaglebattle_/?hl=en">
                      <FaInstagram />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.youtube.com/@eaglebattle">
                      <FaYoutube />
                    </a>
                  </li>
                </ul>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}
