import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { API } from "../../API/Api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import DOMPurify from "dompurify";
import { Button, IconButton } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import axios from "axios";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditProfile({ userData, userBankData, user }) {
  const [value, setValue] = useState(0);
  const [getData, setgetData] = useState([]);
  const [getBankData, setgetBankData] = useState([]);
  const [fileValue, setfileValue] = useState("");
  const [countries, setcountries] = useState([]);
  const [spinner, setspinner] = useState(false);
  console.log("userBankData-->", userBankData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //////////////////////My Referral Link//////////////

  let URL = window.location.origin;
  // console.log("URL",URL);

  //   console.log("file", fileValue);

  /////////////// Profile Update//////////////////////////

  //   const handleGet = (event) => {
  //     const { name, value } = DOMPurify.sanitize(event.target.value);
  //     setgetData({ ...getData, [name]: value });
  //   };

  const handleGet = (event) => {
    const { name, value } = event.target;
    const sanitizedValue = DOMPurify.sanitize(
      value.replace(/[^A-Z0-9]/gi, "").substring(0, 100)
    );
    setgetData({ ...getData, [name]: sanitizedValue });
  };

  //  console.log("getdata", getData );

  const handleBankGet = (event) => {
    const { name, value } = event.target;

    setgetBankData({ ...getBankData, [name]: value });
    if (name === "ifsc") {
      IfscDetail_API(value);
    }
  };

  const [ifscDetails, setifscDetails] = useState({});

  async function IfscDetail_API(value) {
    try {
      let response = await axios.get(`https://ifsc.razorpay.com/${value}`);
      console.log("IESC API", response);
      setifscDetails(response.data);
    } catch (e) {
      console.log("Error in Ip Api", e);
    }
  }

  const handlefile = (event) => {
    let file = event.target.files[0];
    setfileValue(file);
    // console.log("File", file);
  };

  const updateProfile = async () => {
    try {
      setspinner(true);

      let updateRes = await API.post(
        `updateProfileDetails_New`,
        {
          uid: userId,
          name: getData?.firstName || userData.f_name,
          mobile: getData?.mobile || userData.mobile,
          address: getData?.address || userData.address,
          email: getData?.email || userData.email,
          pincode: getData?.pincode || userData.pincode,
          fathername: getData?.fathername || userData.fathername,
          statename: getData?.statename || userData.statename,
          cityname: getData?.cityname || userData.cityname,
          NomineeName: getData?.NomineeName || userData.NomineeName,
          NomineeRelationsh: getData?.NomineeRelationsh || userData.NomineeRelationsh,
          aadhaarno: getData?.aadhaarno || userData.aadhaarno,
          panno: getData?.panno || userData.panno,
          //   dob: getData?.dob || userData?.dob,
          //   pan: getData?.panno || userData.panno,
          //   cityname: getData?.cityname || userData.cityname,
          //   pincode: getData?.pincode || userData.pincode,
          //   countryid: 1,
          //   stateid: 1,
          //   img: "",
          //   salution: "",
          //   adhar: "",
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      //   console.log("getdatain", getData);
      console.log("updateProfileRes-->", updateRes.data);
      if (updateRes.data.data == "Profile Updated Successfully") {
        toast.success(updateRes.data.data);
        setspinner(false);
        // window.location.href = "/Profile";
      } else {
        toast.error(updateRes.data.data);
      }
    } catch (error) {
      toast.error("Something Wrong");

      console.log(error);
      setspinner(false);
    }
  };

  //   const countryList = async () => {
  //     try {
  //       let res = await API.get("/countryList");
  //       // console.log("countryAAA", res.data.data[0]);
  //       setcountries(res.data.data[0]);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   useEffect(() => {
  //     countryList();
  //   }, []);

  ///////////////////////////////e-Currency Address/////////////////////////

  const [getE_CurrencyAddrress, setgetE_CurrencyAddrress] = useState("");
  const [otp_flag, setotp_flag] = useState(false);
  const [getOTP, setgetOTP] = useState("");

  //   const sendOTP = async () => {
  //     try {
  //       let res = await API.post("/ecurrencyVerification", {
  //         uid: user,
  //       });
  //       // console.log("res", res);
  //       if (res.data.success == true) {
  //         setotp_flag(true);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  const updatedAddress = async () => {
    try {
      // console.log("getOTP", getOTP);
      // userBankData.accountaddress
      let res = await API.post(
        "/updateWalletAddress",
        {
          uid: userId,
          walletAddress: getE_CurrencyAddrress || userBankData.accountaddress,
          // otp: getOTP,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("ResUpdated", res.data);
      if (res.data.data == "Updated successfully") {
        toast.success(res.data.data);
        setotp_flag(false);
      } else {
        toast.error(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateBankProfile = async () => {
    try {
      setspinner(true);
      let updateRes = await API.post(
        `updateBankDetails`,
        {
          uid: userId,
          holderName: getBankData?.holder || userBankData.holder,
          branch: ifscDetails.BRANCH || userBankData.branch,
          bankName: ifscDetails.BANK || userBankData.bank_name,
          Ifsc: getBankData?.ifsc || userBankData.ifsc,
          accountNumber: getBankData?.accountnumber || userBankData.accountnumber,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("updatebankRes-->", updateRes.data);
      if (updateRes.data.data == "Updated successfully") {
        toast.success(updateRes.data.data);
        setspinner(false);
        // window.location.href = "/Profile";
      } else {
        toast.error(updateRes.data.data);
      }
    } catch (error) {
      toast.error("Something Wrong");

      console.log(error);
      setspinner(false);
    }
  };

  function handleCopy() {
    let inputText = `${URL}/register?userid=${userId}`;

    navigator.clipboard.writeText(inputText);
    toast.success("Copied");
  }
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab label="About" {...a11yProps(0)} />
            <Tab label="Edit Profile" {...a11yProps(1)} />
            <Tab label="Bank Details" {...a11yProps(2)} />
            <Tab label="Update e-Currency" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <>
            <div className="row">
              <div className="col-md-4 col-4 b-r">
                <label>Full Name</label>
                <p className="ng-binding" style={{ color: "rgb(194, 16, 16)" }}>
                  {userData.f_name}
                </p>
              </div>
              <div className="col-md-4 col-4 b-r">
                <label>Mobile</label>
                <p
                  className=" ng-binding"
                  style={{ color: "rgb(194, 16, 16)" }}
                >
                  {userData.mobile}
                </p>
              </div>
              <div className="col-md-4 col-4 b-r ">
                <label>Email</label>
                <p className="ng-binding" style={{ color: "rgb(194, 16, 16)" }}>
                  {userData.email}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-9 col-6 b-r">
                <Box
                  component="form"
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <TextField
                      fullWidth
                      id="outlined-read-only-input "
                      label="My Referral Link"
                      value={`${URL}/register?userid=${userId}`}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                </Box>
              </div>
              <div className="col-md-3 col-6 b-r">
                <IconButton
                  aria-label="copy"
                  onClick={handleCopy}
                  sx={{
                    color: "red",
                    border: "1px solid red",
                    borderRadius: "5px",
                    padding: "14px",
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  <FileCopyIcon />
                </IconButton>{" "}
              </div>
            </div>
          </>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <>
            <div className="card-header">
              <h4 style={{ color: "rgb(194, 16, 16)" }}>Edit Profile</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="form-group col-md-6 col-12">
                  <span style={{ color: "rgb(194, 16, 16)" }}>Full Name</span>
                  <input
                    type="text"
                    name="firstName"
                    title="first name rquired"
                    required=""
                    maxLength={40}
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="First Name"
                    defaultValue={userData.f_name}
                    onChange={handleGet}
                  />
                </div>
                <div className="form-group col-md-6 col-12">
                  <span style={{ color: "rgb(194, 16, 16)" }}>Father Name</span>
                  <input
                    type="text"
                    name="fathername"
                    title="Father name rquired"
                    required=""
                    maxLength={40}
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="Father Name"
                    defaultValue={userData.fathername}
                    onChange={handleGet}
                  />
                </div>            
                <div className="form-group col-md-7 col-12">
                <span style={{ color: "rgb(194, 16, 16)" }}>Email Id</span>
                  <input
                    type="text"
                    ng-model="p.email"
                    name="email"
                    maxLength={70}
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    required=""
                    title="Email is required"
                    placeholder="Email"
                    defaultValue={userData.email}
                    onChange={handleGet}
                  />                 
                </div>
                <div className="form-group col-md-5 col-12">
                <span style={{ color: "rgb(194, 16, 16)" }}>Mobile No.</span>
                  <input
                    type="text"
                    name="mobile"
                    maxLength={12}
                    className="form-control ng-pristine ng-untouched ng-valid-maxlength ng-not-empty ng-valid ng-valid-required"
                    required=""
                    title="Phone No. is  required"
                    placeholder="Phone Number"
                    defaultValue={userData.mobile}
                    onChange={handleGet}
                  />
                </div>
          
                <div className="form-group col-md-12 col-12">
                <span style={{ color: "rgb(194, 16, 16)" }}>Full Address</span>
                  <input
                    type="text"
                    name="address"
                    maxLength={70}
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    required=""
                    title="Address is required"
                    placeholder="Address"
                    defaultValue={userData.address}
                    onChange={handleGet}
                  />                
                </div>
                <div className="form-group col-md-4 col-12">
                <span style={{ color: "rgb(194, 16, 16)" }}>State</span>
                  <input
                    type="text"
                    name="statename"
                    maxLength={30}
                    className="form-control ng-pristine ng-untouched ng-valid-maxlength ng-not-empty ng-valid ng-valid-required"
                    required=""
                    title="State is  required"
                    placeholder="State"
                    defaultValue={userData.statename}
                    onChange={handleGet}
                  />
                </div>
                <div className="form-group col-md-4 col-12">
                <span style={{ color: "rgb(194, 16, 16)" }}>City</span>
                  <input
                    type="text"
                    name="cityname"
                    maxLength={30}
                    className="form-control ng-pristine ng-untouched ng-valid-maxlength ng-not-empty ng-valid ng-valid-required"
                    required=""
                    title="City is required"
                    placeholder="City"
                    defaultValue={userData.cityname}
                    onChange={handleGet}
                  />
                </div>
                <div className="form-group col-md-4 col-12">
                <span style={{ color: "rgb(194, 16, 16)" }}>Pin Code</span>
                  <input
                    type="text"
                    name="pincode"
                    maxLength={10}
                    className="form-control ng-pristine ng-untouched ng-valid-maxlength ng-not-empty ng-valid ng-valid-required"
                    required=""
                    title="Pincode is  required"
                    placeholder="Pincode"
                    defaultValue={userData.pincode}
                    onChange={handleGet}
                  />
                </div>
          
                <div className="form-group col-md-6 col-12">
                <span style={{ color: "rgb(194, 16, 16)" }}>Aadhar No.</span>
                  <input
                    type="text"
                    name="aadhaarno"
                    maxLength={70}
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    required=""
                    title="Aadhaar No. is required"
                    placeholder="Aadhar No."
                    defaultValue={userData.aadhaarno}
                    onChange={handleGet}
                  />
                </div>
                <div className="form-group col-md-6 col-12">
                <span style={{ color: "rgb(194, 16, 16)" }}>PAN No.</span>
                  <input
                    type="text"
                    name="panno"
                    maxLength={12}
                    className="form-control ng-pristine ng-untouched ng-valid-maxlength ng-not-empty ng-valid ng-valid-required"
                    required=""
                    title="PAN No. is required"
                    placeholder="PAN No."
                    defaultValue={userData.panno}
                    onChange={handleGet}
                  />
                </div>              
           
                <div className="form-group col-md-6 col-12">
                <span style={{ color: "rgb(194, 16, 16)" }}>Nominee Name</span>
                  <input
                    type="text"
                    name="NomineeName"
                    maxLength={70}
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    required=""
                    title="Nominee Name is required"
                    placeholder="Nominee Name"
                    defaultValue={userData.NomineeName}
                    onChange={handleGet}
                  />
                </div>
                <div className="form-group col-md-6 col-12">
                <span style={{ color: "rgb(194, 16, 16)" }}>Nominee Relation</span>
                  <input
                    type="text"
                    name="NomineeRelationsh"
                    maxLength={12}
                    className="form-control ng-pristine ng-untouched ng-valid-maxlength ng-not-empty ng-valid ng-valid-required"
                    required=""
                    title="Nominee Relation is required"
                    placeholder="Nominee Relation"
                    defaultValue={userData.NomineeRelationsh}
                    onChange={handleGet}
                  />
                </div>              
              </div>
            </div>
           <div className="card-footer text-end">
              <button
                type="button"
                ng-click=""
                className="btn btn-success"
                style={{ height: "auto" }}
                onClick={updateProfile}
              >
                {spinner ? "Loading..." : "Save Changes"}
              </button>
            </div>
          </>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <>
            <div className="card-header">
              <h4 style={{ color: "rgb(194, 16, 16)" }}>Bank Details</h4>
            </div>
            <div className="card-body">
              <input
                type="hidden"
                name="_token"
                defaultValue=""
                autoComplete="off"
              />
              <div className="row">
                <div className="form-group col-md-4 col-12">
                  <label>Accountholder Name</label>
                  <input
                    type="text"
                    ng-model="p.holder"
                    name="holder"
                    title="Accountholder Name required"
                    required=""
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="Accountholder Name"
                    defaultValue={userBankData.holder}
                    onChange={handleBankGet}
                  />
                </div>
                <div className="form-group col-md-4 col-12">
                  <label>Account No.</label>
                  <input
                    type="text"
                    ng-model="p.accountnumber"
                    name="accountnumber"
                    title="Account No. required"
                    required=""
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="Account No."
                    defaultValue={userBankData.accountnumber}
                    onChange={handleBankGet}
                  />
                </div>
                <div className="form-group col-md-4 col-12">
                  <label>IFSC</label>
                  <input
                    type="text"
                    ng-model="p.ifsc"
                    name="ifsc"
                    title="IFSC required"
                    required=""
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="IFSC"
                    defaultValue={userBankData.ifsc}
                    onChange={handleBankGet}
                  />
                </div>
                <div className="form-group col-md-4 col-12">
                  <label>Bank Name</label>
                  <input
                    type="text"
                    ng-model="p.bank_name"
                    name="bank_name"
                    title="Bank Name required"
                    required=""
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="Bank Name"
                    value={ifscDetails.BANK || userBankData.bank_name}
                    // onChange={handleBankGet}
                    readOnlys
                  />
                </div>
                <div className="form-group col-md-4 col-12">
                  <label>Branch Name</label>
                  <input
                    type="text"
                    ng-model="p.branch"
                    name="branch"
                    title="Branch Name required"
                    required=""
                    className="form-control ng-pristine ng-untouched ng-not-empty ng-valid ng-valid-required"
                    placeholder="Branch Name"
                    value={ifscDetails.BRANCH || userBankData.branch}
                    // onChange={handleBankGet}
                    readOnly
                  />
                </div>
              </div>

              <button
                type="button"
                id="btnupdate"
                className="btn btn-success updateU"
                style={{ height: "auto" }}
                onClick={() => updateBankProfile()}
              >
                {"Update Bank Details"}
              </button>
            </div>
          </>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <>
            <div className="card-header">
              <h4>e-Currency Address</h4>
            </div>
            <div className="card-body">
              <input
                type="hidden"
                name="_token"
                defaultValue=""
                autoComplete="off"
              />
              <div className="form-group">
                <label>Your e-Currency Wallet Address</label>
                <input
                  type="text"
                  ng-model="p.Accountaddress"
                  className="form-control validateOtp ng-pristine ng-untouched ng-valid ng-empty"
                  name="Accountaddress"
                  id="Accountaddress"
                  defaultValue={userBankData.accountaddress}
                  placeholder="e-Currency Address"
                  onChange={(e) => setgetE_CurrencyAddrress(e.target.value)}
                />
                <span id="res_otp" />
              </div>
              {/* <div
                className="form-group "
                id="divtxtotp"
                style={{ display: otp_flag ? "" : "none" }}
              >
                <label>OTP</label>
                <input
                  type="text"
                  className="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-maxlength"
                  ng-model="p.otp"
                  name="otp"
                  id="otp"
                  defaultValue=""
                  maxLength={6}
                  autoComplete="off"
                  placeholder="One Time Password"
                  onChange={(e) => setgetOTP(e.target.value)}
                />
              </div> */}

              {/* <button
                type="button"
                id="btnsubmit"
                ng-click="updateEcurrencyDetails()"
                style={{ display: "none" }}
                className="btn btn-success updateU"
              >
                Save
              </button> */}

              <button
                type="button"
                id="btnotp"
                className="btn btn-success updateU"
                style={{ height: "auto" }}
                onClick={updatedAddress}
                // onClick={() => (otp_flag ? updatedAddress() : sendOTP())}
              >
                Update e-Currency Address
                {/* {otp_flag ? "Update e-Currency Address" : "Send OTP"} */}
              </button>
            </div>
          </>
        </CustomTabPanel>
      </Box>
    </>
  );
}
