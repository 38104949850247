import React, { useState } from "react";
import "./withdrawal.css";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import { API } from "../../API/Api";
import { toast } from "react-toastify";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";

const WithDrawal = () => {
  const dispatch = useDispatch();
  const [showSidebar, setShowSidebar] = useState(false);
  const [wallettype, setWalletType] = useState("");
  const [amount, setAmount] = useState("");
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };
  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const { Net_Balance } = useSelector((state) => state.Auth.dashboardData);
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (!wallettype) {
        toast.error("Select Wallet Type");
        return;
      }
      if (!amount || amount <= 0) {
        toast.error("Invalid Amount");
        return;
      }

      let responce = await API.post(
        `/withdrawal`,
        {
          uid: userId,
          walletType: wallettype,
          amount: amount,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Submit API", responce);
      if (responce.data.data === "success") {
        toast.success(responce.data.data);

        setAmount("");
        setWalletType("");
      } else {
        toast.error(responce.data.data);
      }
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          UpdateAuth({
            isAuth: false,
            userId: null,
            jwt_token: null,
          })
        );
      }
    }
  }
  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} />
      <Footer />
      <div className="col-lg-10">
        <div className="row">
          <div className="col-12 px-2 ">
            <div className="withdrawal-main-div mt-1">
              <div className="F-h p-2 pb-0">
                <p className="ms-3">WithDrawal</p>
              </div>

              <div className="form-area d-flex justify-content-center align-items-center">
                <div className="d-flex justify-content-center flex-column">
                  {/* <div className="F-h p-2 pb-0">
                    <p className="ms-3">Upload USDT Amount</p>
                  </div> */}
                  <div className="card w-card mt-4">
                    <div className="mb-0">
                      <p className="b-o ms-4 mt-4">
                        Available Balance: {parseFloat(Net_Balance).toFixed(2)}{" "}
                      </p>
                    </div>

                    <div className="px-4">
                      <form>
                        <div className="form-group">
                          <label htmlFor="type">Wallet Type</label>
                          <select
                            id="type"
                            className="form-control mt-2 user-id-input"
                            value={wallettype}
                            onChange={(e) => setWalletType(e.target.value)}
                          >
                            <option value="" disabled>
                              Select Wallet Type
                            </option>
                            <option value="1">Wallet (USDT)</option>
                            <option value="2">Bank (INR)</option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label htmlFor="amount">Amount</label>
                          <input
                            type="text"
                            className="form-control mt-2"
                            id="amount"
                            aria-describedby="amount"
                            placeholder="Enter  Amount"
                            value={amount}
                            onChange={(e) =>
                              setAmount(
                                DOMPurify.sanitize(
                                  e.target.value
                                    .replace(/[^0-9]/g, "")
                                    .substring(0, 5)
                                )
                              )
                            }
                          />
                        </div>

                        <button
                          className="btn transfer-btn mt-4"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithDrawal;
