import React from "react";
import { FaEnvelope, FaFacebookF, FaMapMarker } from "react-icons/fa";
import { GrReddit } from "react-icons/gr";
import { FaInstagram } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

export default function Footerr() {
  return (
    <div>
      <footer>
        <div className="footer-top-wrap">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-9">
                <div className="footer-widget">
                  <div className="footer-logo mb-25">
                    <a href="index.html">
                      <img src="assets/img/logo/logo.gif" alt="" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Useful Links</h4>
                  <ul className="fw-links">
                    <li>
                      <a href="#nft">All NFTs</a>
                    </li>
                    <li>
                      <a href="#Collection">Collection</a>
                    </li>
                    <li>
                      <a href="#Explore">Explore</a>
                    </li>
                    <li>
                      <a href="#Team">Team</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Community</h4>
                  <ul className="fw-links">
                    <li>
                      <a href="#News">News</a>
                    </li>
                    <li>
                      <a href="#">Privacy &amp; Terms</a>
                    </li>
                    <li>
                      <a href="#Footer">Help Center</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Follow Us</h4>
                  <p>
                    <FaEnvelope /> support@eaglebattle.club
                  </p>
                  <p>
                    <FaMapMarker /> Prism Tower, Bussiness
                    Bay, Dubai, UAE
                  </p>
                  <ul className="footer-social">
                    <li>
                      <a target="_blank" href="https://www.facebook.com/people/Eagle-Battle/100083871291277/">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.reddit.com/user/Ok_Village7281/?rdt=61285">
                        <GrReddit />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.instagram.com/eaglebattle_/?hl=en">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://twitter.com/i/flow/login?redirect_after_login=%2FEagleBattle_">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://t.me/eaglebattle">
                        <FaTelegram />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://www.youtube.com/@eaglebattle">
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="copyright-text">
                  <p>
                    All rights reserved © 2024 by{" "}
                    <a href="#">eaglebattle.club</a>
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <ul className="copyright-link-list">
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#">Terms And Condition</a>
                  </li>
                  <li>
                    <a href="#">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
