import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Sliddeer from "./Sliddeer";
import Features from "./Features";
import About from "./About";
import Contact from "./Contact";
import Footerr from "./Footerr";
import { MdFilterAlt, MdOutlineMiscellaneousServices } from "react-icons/md";
import { TbMessageCircleQuestion } from "react-icons/tb";
import { BsFillCloudDownloadFill } from "react-icons/bs";
import { TiDownload } from "react-icons/ti";
import { FaCalendarAlt, FaUserCircle } from "react-icons/fa";
// import "./Hero.css";

export default function Hero() {
  return (
    <div>
      <Header />
      <main>
        <div className="banner-bg">
          {/* banner-area */}
          <div className="banner-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-8">
                  <div className="banner-content">
                    <h2 className="title">
                      Buy and sell Eagle Battle <br /> <span>NFT</span>
                    </h2>
                    <p>
                      Everything you need to build, host, scale amazing dapps
                      free by creating account today
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* banner-area-end */}
          {/* top-seller-area */}
          <div className="top-seller-area">
            <div className="container">
              <div className="top-seller-wrap">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title mb-40">
                      <h2 className="title">
                        Top Seller{" "}
                        <img src="assets/img/icons/title_icon01.png" alt="" />
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="top-seller-item">
                      <div className="top-seller-img">
                        <img
                          src="assets/img/others/top-seller-img.jpg"
                          alt=""
                        />
                      </div>
                      <div className="top-seller-content">
                        <h5 className="title">
                          <a href="#">Alan walker</a>
                        </h5>
                        <p>
                          858.5 <span>BNB</span>
                        </p>
                        <p>Coming Soon</p>
                        {/* <ul class="icon">
                                              <li><a href="collections.html"><i class="fi-sr-pharmacy"></i></a></li>
                                              <li><a href="#"><i class="fi-sr-share"></i></a></li>
                                          </ul> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="top-seller-item">
                      <div className="top-seller-img">
                        <img
                          src="assets/img/others/top-seller-img02.jpg"
                          alt=""
                        />
                      </div>
                      <div className="top-seller-content">
                        <h5 className="title">
                          <a href="#">Mazanov </a>
                        </h5>
                        <p>
                          585.5 <span>BNB</span>
                        </p>
                        <p>Coming Soon</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="top-seller-item">
                      <div className="top-seller-img">
                        <img
                          src="assets/img/others/top-seller-img03.jpg"
                          alt=""
                        />
                      </div>
                      <div className="top-seller-content">
                        <h5 className="title">
                          <a href="#">Alvin Nov</a>
                        </h5>
                        <p>
                          585.5 <span>BNB</span>
                        </p>
                        <p>Coming Soon</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="top-seller-item">
                      <div className="top-seller-img">
                        <img
                          src="assets/img/others/top-seller-img04.jpg"
                          alt=""
                        />
                      </div>
                      <div className="top-seller-content">
                        <h5 className="title">
                          <a href="#">Jimmy Dom</a>
                        </h5>
                        <p>
                          585.5 <span>BNB</span>
                        </p>
                        <p>Coming Soon</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* top-seller-area-end */}
        </div>
        {/* top-collection-area */}
        <section className="top-collection-area" id="Collection">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="section-title mb-40">
                  <h2 className="title">
                    Top collections{" "}
                    <img src="assets/img/icons/title_icon01.png" alt="" />
                  </h2>
                </div>
              </div>
              <div className="col-md-6">
                <div className="top-collection-nav" />
              </div>
            </div>
            <div className="row top-collection-active">
              <div className="col-lg-3">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar">
                        <a href="#" className="thumb">
                          <img
                            src="assets/img/others/top_col_avatar.png"
                            alt=""
                          />
                        </a>
                        By{" "}
                        <a href="#" className="name">
                          Jonson
                        </a>
                      </li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          role="button"
                          aria-expanded="false"
                        />
                        <ul className="dropdown-menu">
                          <li>
                            <a href="#">Artwork</a>
                          </li>
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Author Action</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="#">
                      <img src="assets/img/1.svg" alt="" />
                    </a>
                  </div>
                  <div className="collection-item-content">
                    <h5 className="title">
                      <a href="#">NFT Collection</a>{" "}
                      <span className="price">5.4 BNB</span>
                    </h5>
                  </div>
                  <div className="collection-item-bottom">
                    <ul>
                      <li className="bid">
                        <a href="#" className="btn">
                          place a bid
                        </a>
                      </li>
                      <li className="wishlist">
                        <a href="#">59</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar">
                        <a href="#" className="thumb">
                          <img
                            src="assets/img/others/top_col_avatar.png"
                            alt=""
                          />
                        </a>
                        By{" "}
                        <a href="#" className="name">
                          James
                        </a>
                      </li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          role="button"
                          aria-expanded="false"
                        />
                        <ul className="dropdown-menu">
                          <li>
                            <a href="#">Artwork</a>
                          </li>
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Author Action</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="#">
                      <img src="assets/img/2.svg" alt="" />
                    </a>
                  </div>
                  <div className="collection-item-content">
                    <h5 className="title">
                      <a href="#">Colorful Abstract</a>{" "}
                      <span className="price">5.4 BNB</span>
                    </h5>
                  </div>
                  <div className="collection-item-bottom">
                    <ul>
                      <li className="bid">
                        <a href="#" className="btn">
                          place a bid
                        </a>
                      </li>
                      <li className="wishlist">
                        <a href="#">59</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar">
                        <a href="#" className="thumb">
                          <img
                            src="assets/img/others/top_col_avatar.png"
                            alt=""
                          />
                        </a>
                        By{" "}
                        <a href="#" className="name">
                          Robert
                        </a>
                      </li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          role="button"
                          aria-expanded="false"
                        />
                        <ul className="dropdown-menu">
                          <li>
                            <a href="#">Artwork</a>
                          </li>
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Author Action</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="#">
                      <img src="assets/img/3.svg" alt="" />
                    </a>
                  </div>
                  <div className="collection-item-content">
                    <h5 className="title">
                      <a href="#">The girl firefly</a>{" "}
                      <span className="price">5.4 BNB</span>
                    </h5>
                  </div>
                  <div className="collection-item-bottom">
                    <ul>
                      <li className="bid">
                        <a href="#" className="btn">
                          place a bid
                        </a>
                      </li>
                      <li className="wishlist">
                        <a href="#">59</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar">
                        <a href="#" className="thumb">
                          <img
                            src="assets/img/others/top_col_avatar.png"
                            alt=""
                          />
                        </a>
                        By{" "}
                        <a href="#" className="name">
                          Michael{" "}
                        </a>
                      </li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                        <a
                          href="#"
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          role="button"
                          aria-expanded="false"
                        />
                        <ul className="dropdown-menu">
                          <li>
                            <a href="#">Artwork</a>
                          </li>
                          <li>
                            <a href="#">Action</a>
                          </li>
                          <li>
                            <a href="#">Author Action</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="#">
                      <img src="assets/img/4.svg" alt="" />
                    </a>
                  </div>
                  <div className="collection-item-content">
                    <h5 className="title">
                      <a href="#">Forest Princess</a>{" "}
                      <span className="price">5.4 BNB</span>
                    </h5>
                  </div>
                  <div className="collection-item-bottom">
                    <ul>
                      <li className="bid">
                        <a href="#" className="btn">
                          place a bid
                        </a>
                      </li>
                      <li className="wishlist">
                        <a href="#">59</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* top-collection-area-end */}
        <div className="area-bg">
          {/* week-features-area */}
          <section className="week-features-area" id="Team">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title mb-35">
                    <h2 className="title">
                      Player of the week{" "}
                      <img src="assets/img/icons/title_icon02.png" alt="" />
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-5">
                  <div className="week-features-item">
                    <div className="features-item-top">
                      <div className="features-avatar">
                        <div className="features-avatar-thumb">
                          <a href="#">
                            <img
                              src="assets/img/others/features_avatar.png"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="features-avatar-info">
                          <h5 className="title">
                            <a href="#">Deploy Abstract</a>
                          </h5>
                          {/*  <span>Created by <a href="#">Konx</a></span> */}
                        </div>
                      </div>
                      <div className="features-item-wishlist">
                        <a href="#">100</a>
                      </div>
                    </div>
                    <div className="row"></div>
                    <ul className="features-img-wrap1 row">
                      <li className="grid-item1">
                        <center>
                          <img src="assets/img/1.png" alt="" />
                        </center>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5">
                  <div className="week-features-item">
                    <div className="features-item-top">
                      <div className="features-avatar">
                        <div className="features-avatar-thumb">
                          <a href="#">
                            <img
                              src="assets/img/others/features_avatar02.png"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="features-avatar-info">
                          <h5 className="title">
                            <a href="#">Random Abstract</a>
                          </h5>
                        </div>
                      </div>
                      <div className="features-item-wishlist">
                        <a href="#">300</a>
                      </div>
                    </div>
                    <ul className="features-img-wrap1 row">
                      <li className="grid-item1">
                        <center>
                          <img src="assets/img/2.png" alt="" />
                        </center>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5">
                  <div className="week-features-item">
                    <div className="features-item-top">
                      <div className="features-avatar">
                        <div className="features-avatar-thumb">
                          <a href="#">
                            <img
                              src="assets/img/others/features_avatar03.png"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="features-avatar-info">
                          <h5 className="title">
                            <a href="#">Colorful Abstract</a>
                          </h5>
                        </div>
                      </div>
                      <div className="features-item-wishlist">
                        <a href="#">190</a>
                      </div>
                    </div>
                    <ul className="features-img-wrap1 row">
                      <li className="grid-item1">
                        <center>
                          <img src="assets/img/3.png" alt="" />
                        </center>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* week-features-area-end */}
          {/* sell-nfts-area */}
          <section className="sell-nfts-area" id="nft">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title mb-45">
                    <h2 className="title">
                      Buy And Sell Your NFTs{" "}
                      <img src="assets/img/icons/title_icon03.png" alt="" />
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="sell-nfts-item">
                    <img
                      src="assets/img/icons/nfts_01.png"
                      alt=""
                      className="icon"
                    />
                    <span className="step-count">go step 1</span>
                    <h5 className="title">Buy And Sell</h5>
                    <p>
                      Wallet that is functional for NFT purchasing. You have
                      Meta mask or trust wallet account at this point.
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="sell-nfts-item">
                    <img
                      src="assets/img/icons/nfts_02.png"
                      alt=""
                      className="icon"
                    />
                    <span className="step-count">go step 2</span>
                    <h5 className="title">Create Collection</h5>
                    <p>
                      Wallet that is functional for NFT purchasing. You have
                      Meta mask or trust wallet account at this point.
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="sell-nfts-item">
                    <img
                      src="assets/img/icons/nfts_03.png"
                      alt=""
                      className="icon"
                    />
                    <span className="step-count">go step 3</span>
                    <h5 className="title">Add Your NFTs</h5>
                    <p>
                      Wallet that is functional for NFT purchasing. You have
                      Meta mask or trust wallet account at this point.
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="sell-nfts-item">
                    <img
                      src="assets/img/icons/nfts_04.png"
                      alt=""
                      className="icon"
                    />
                    <span className="step-count">go step 4</span>
                    <h5 className="title">List Them For Sale</h5>
                    <p>
                      Wallet that is functional for NFT purchasing. You have
                      Meta mask or trust wallet account at this point.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* sell-nfts-area-end */}
        </div>
        {/* explore-products-area */}
        <section className="explore-products-area" id="Explore">
          <div className="container">
            <div className="row mb-35">
              <div className="col-md-7 col-sm-8">
                <div className="section-title">
                  <h2 className="title">
                    Explore Product{" "}
                    <img src="assets/img/icons/title_icon01.png" alt="" />
                  </h2>
                </div>
              </div>
              <div className="col-md-5 col-sm-4">
                <div className="section-button text-end">
                  <a href="#" className="btn filter-btn filter-toggle">
                    <MdFilterAlt /> filter
                  </a>
                </div>
              </div>
            </div>
            <div className="filter-category-wrap">
              <div className="row">
                <div className="col-12">
                  <ul className="category-list">
                    <li className="active">
                      <a href="index-2.html">
                        <img src="assets/img/icons/cat_001.png" alt="" /> All
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="assets/img/icons/cat_01.png" alt="" /> Games
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="assets/img/icons/cat_02.png" alt="" /> Art
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="assets/img/icons/cat_03.png" alt="" /> Trading
                        Cards
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="assets/img/icons/cat_04.png" alt="" /> Music
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="assets/img/icons/cat_05.png" alt="" /> Domain
                        Names
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="assets/img/icons/cat_06.png" alt="" /> Memes
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="assets/img/icons/cat_07.png" alt="" />{" "}
                        Collectibles
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar">
                        <a href="#" className="thumb">
                          <img
                            src="assets/img/others/top_col_avatar.png"
                            alt=""
                          />
                        </a>
                        By{" "}
                        <a href="#" className="name">
                          David
                        </a>
                      </li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                        {/*  <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false"></a>
                                          <ul class="dropdown-menu">
                                              <li><a href="#">Artwork</a></li>
                                              <li><a href="#">Action</a></li>
                                              <li><a href="#">Author Action</a></li>
                                          </ul> */}
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="#">
                      <img src="assets/img/1.svg" alt="" />
                    </a>
                  </div>
                  <div className="collection-item-content">
                    {/* <h5 class="title"><a href="#">NFT Collection</a> */}{" "}
                    <span className="price">5.4 BNB</span>
                  </div>
                  <div className="collection-item-bottom">
                    <ul>
                      <li className="bid">
                        <a href="#" className="btn">
                          place a bid
                        </a>
                      </li>
                      <li className="wishlist">
                        <a href="#">59</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar">
                        <a href="#" className="thumb">
                          <img
                            src="assets/img/others/top_col_avatar.png"
                            alt=""
                          />
                        </a>
                        By{" "}
                        <a href="#" className="name">
                          William
                        </a>
                      </li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="#">
                      <img src="assets/img/2.svg" alt="" />
                    </a>
                  </div>
                  <div className="collection-item-content">
                    {/* <h5 class="title"><a href="#">Colorful Abstract</a>  */}
                    <span className="price">5.4 BNB</span>
                  </div>
                  <div className="collection-item-bottom">
                    <ul>
                      <li className="bid">
                        <a href="#" className="btn">
                          place a bid
                        </a>
                      </li>
                      <li className="wishlist">
                        <a href="#">59</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar">
                        <a href="#" className="thumb">
                          <img
                            src="assets/img/others/top_col_avatar.png"
                            alt=""
                          />
                        </a>
                        By{" "}
                        <a href="#" className="name">
                          Richard{" "}
                        </a>
                      </li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="#">
                      <img src="assets/img/3.svg" alt="" />
                    </a>
                  </div>
                  <div className="collection-item-content">
                    {/* <h5 class="title"><a href="#">The Girl Firefly</a> */}{" "}
                    <span className="price">5.4 BNB</span>
                  </div>
                  <div className="collection-item-bottom">
                    <ul>
                      <li className="bid">
                        <a href="#" className="btn">
                          place a bid
                        </a>
                      </li>
                      <li className="wishlist">
                        <a href="#">59</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar">
                        <a href="#" className="thumb">
                          <img
                            src="assets/img/others/top_col_avatar.png"
                            alt=""
                          />
                        </a>
                        By{" "}
                        <a href="#" className="name">
                          Thomas
                        </a>
                      </li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="#">
                      <img src="assets/img/4.svg" alt="" />
                    </a>
                  </div>
                  <div className="collection-item-content">
                    {/* <h5 class="title"><a href="#">Forest Princess</a>  */}
                    <span className="price">5.4 BNB</span>
                  </div>
                  <div className="collection-item-bottom">
                    <ul>
                      <li className="bid">
                        <a href="#" className="btn">
                          place a bid
                        </a>
                      </li>
                      <li className="wishlist">
                        <a href="#">59</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar">
                        <a href="#" className="thumb">
                          <img
                            src="assets/img/others/top_col_avatar.png"
                            alt=""
                          />
                        </a>
                        By{" "}
                        <a href="#" className="name">
                          Daniel
                        </a>
                      </li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="#">
                      <img src="assets/img/1.svg" alt="" />
                    </a>
                  </div>
                  <div className="collection-item-content">
                    {/* <h5 class="title"><a href="#">NFT Painting</a> */}{" "}
                    <span className="price">5.4 BNB</span>
                  </div>
                  <div className="collection-item-bottom">
                    <ul>
                      <li className="bid">
                        <a href="#" className="btn">
                          place a bid
                        </a>
                      </li>
                      <li className="wishlist">
                        <a href="#">59</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar">
                        <a href="#" className="thumb">
                          <img
                            src="assets/img/others/top_col_avatar.png"
                            alt=""
                          />
                        </a>
                        By{" "}
                        <a href="#" className="name">
                          Matthew
                        </a>
                      </li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="#">
                      <img src="assets/img/2.svg" alt="" />
                    </a>
                  </div>
                  <div className="collection-item-content">
                    {/* <h5 class="title"><a href="#">Colorful Abstract</a> */}{" "}
                    <span className="price">5.4 BNB</span>
                  </div>
                  <div className="collection-item-bottom">
                    <ul>
                      <li className="bid">
                        <a href="#" className="btn">
                          place a bid
                        </a>
                      </li>
                      <li className="wishlist">
                        <a href="#">59</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar">
                        <a href="#" className="thumb">
                          <img
                            src="assets/img/others/top_col_avatar.png"
                            alt=""
                          />
                        </a>
                        By{" "}
                        <a href="#" className="name">
                          Charles{" "}
                        </a>
                      </li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="#">
                      <img src="assets/img/2.svg" alt="" />
                    </a>
                  </div>
                  <div className="collection-item-content">
                    {/* <h5 class="title"><a href="#">Bid Collection</a> */}{" "}
                    <span className="price">5.4 BNB</span>
                  </div>
                  <div className="collection-item-bottom">
                    <ul>
                      <li className="bid">
                        <a href="#" className="btn">
                          place a bid
                        </a>
                      </li>
                      <li className="wishlist">
                        <a href="#">59</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                <div className="top-collection-item">
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar">
                        <a href="#" className="thumb">
                          <img
                            src="assets/img/others/top_col_avatar.png"
                            alt=""
                          />
                        </a>
                        By{" "}
                        <a href="#" className="name">
                          Anthony
                        </a>
                      </li>
                      <li className="info-dots dropdown">
                        <span />
                        <span />
                        <span />
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <a href="#">
                      <img src="assets/img/1.svg" alt="" />
                    </a>
                  </div>
                  <div className="collection-item-content">
                    {/*  <h5 class="title"><a href="#">NFT Collection</a>  */}
                    <span className="price">5.4 BNB</span>
                  </div>
                  <div className="collection-item-bottom">
                    <ul>
                      <li className="bid">
                        <a href="#" className="btn">
                          place a bid
                        </a>
                      </li>
                      <li className="wishlist">
                        <a href="#">59</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* explore-products-area-end */}
        {/* latest-news */}
        <section className="latest-news-area" id="News">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="section-title mb-45">
                  <h2 className="title">
                    Latest News Update{" "}
                    <img src="assets/img/icons/title_icon01.png" alt="" />
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-4 col-md-6 col-sm-9">
                <div className="latest-news-item">
                  <div className="latest-news-thumb">
                    <img src="assets/img/blog/news_thumb01.png" alt="" />
                  </div>
                  <div className="latest-news-content">
                    <ul className="latest-news-meta">
                      <li>
                        <FaUserCircle />
                        <a href="#">News</a>
                      </li>
                      <li>
                        <FaCalendarAlt /> Jan 12, 2024
                      </li>
                    </ul>
                    <h4 className="title">
                      <a href="#">
                        Top 5 Most Popular NFT Games world most powerful ?
                      </a>
                    </h4>
                    <a href="#" className="btn">
                      read more
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-9">
                <div className="latest-news-item">
                  <div className="latest-news-thumb">
                    <img src="assets/img/blog/news_thumb02.png" alt="" />
                  </div>
                  <div className="latest-news-content">
                    <ul className="latest-news-meta">
                      <li>
                        <FaUserCircle />
                        <a href="#">News</a>
                      </li>
                      <li>
                        <FaCalendarAlt /> Jan 19, 2024
                      </li>
                    </ul>
                    <h4 className="title">
                      <a href="#">
                        NFTs, rare digital items worlds crypto collectibles
                      </a>
                    </h4>
                    <a href="#" className="btn">
                      read more
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 col-sm-9">
                <div className="latest-news-item">
                  <div className="latest-news-thumb">
                    <img src="assets/img/blog/news_thumb03.png" alt="" />
                  </div>
                  <div className="latest-news-content">
                    <ul className="latest-news-meta">
                      <li>
                        <FaUserCircle />
                        <a href="#">News</a>
                      </li>
                      <li>
                        <FaCalendarAlt /> Jan 19, 2024
                      </li>
                    </ul>
                    <h4 className="title">
                      <a href="#">
                        Crypto enthusiasts on a single platform to create
                      </a>
                    </h4>
                    <a href="#" className="btn">
                      read more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* latest-news-end */}
        {/* design-ways-area */}
        <section className="design-ways-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <div className="design-ways-item">
                  <div className="overlay-icon">
                    <BsFillCloudDownloadFill />
                  </div>
                  <div className="icon">
                    <BsFillCloudDownloadFill />
                  </div>
                  <div className="content">
                    <h3 className="title">Free</h3>
                    <p>Free download</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <div className="design-ways-item">
                  <div className="overlay-icon">
                    <TiDownload />
                  </div>
                  <div className="icon">
                    <TiDownload />
                  </div>
                  <div className="content">
                    <h3 className="title">Unlimited</h3>
                    <p>Personal &amp; commercial use</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <div className="design-ways-item">
                  <div className="overlay-icon">
                    <TbMessageCircleQuestion />
                  </div>
                  <div className="icon">
                    <TbMessageCircleQuestion />
                  </div>
                  <div className="content">
                    <h3 className="title">Support</h3>
                    <p>You can download</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <div className="design-ways-item">
                  <div className="overlay-icon">
                    <MdOutlineMiscellaneousServices />
                  </div>
                  <div className="icon">
                    <MdOutlineMiscellaneousServices />
                  </div>
                  <div className="content">
                    <h3 className="title">Private</h3>
                    <p>Personal data collection</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* design-ways-area-end */}
      </main>

      <Footerr />
    </div>
  );
}
