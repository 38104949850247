import React, { useEffect, useRef, useState } from "react";
import "../Deposit/Deposit.css";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../API/Api";
import { toast } from "react-toastify";
import { UpdateAuth } from "../../Redux/AuthSlice";
import DOMPurify from "dompurify";

const FundRequestUPI = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const [showSidebar, setShowSidebar] = useState(false);
  const [bankName, setBankName] = useState("");
  const [amount, setAmount] = useState("");
  const [txn, setTxn] = useState("");
  const [bankDetails, setbankDetails] = useState([]);
  const [AdminBankDetail, setAdminBankDetail] = useState([]);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };

  const Bank_Detail_API = async () => {
    try {
      let responce = await API.get(
        "adminUpiDetails",

        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Bank Details", responce);
      setbankDetails(responce.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          UpdateAuth({
            isAuth: false,
            userId: null,
            jwt_token: null,
          })
        );
      }
    }
  };

  useEffect(() => {
    Bank_Detail_API();
  }, []);

  const handleSelect = (e) => {
    const { value } = e.target;
    setBankName(value);
    bankDetailbyId(value);
  };

  const bankDetailbyId = async (id) => {
    try {
      let responce = await API.get(`/adminBankDetailsByBankid?id=${id}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("Bank Details By Id", responce);
      setAdminBankDetail(responce.data.data[0]);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          UpdateAuth({
            isAuth: false,
            userId: null,
            jwt_token: null,
          })
        );
      }
    }
  };

  const handleAmount = (e) => {
    let value = DOMPurify.sanitize(e.target.value);
    value = value.replace(/[^0-9]/gi, "").substring(0, 8);
    setAmount(value);
  };
  const handleTxn = (e) => {
    let value = DOMPurify.sanitize(e.target.value);
    value = value.replace(/[^A-Z0-9]/gi, "").substring(0, 30);
    setTxn(value);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (!bankName) {
        toast.error("Select Bank Name");
        return;
      }
      if (!amount || amount <= 0) {
        toast.error("Invalid Amount");
        return;
      }
      if (!txn) {
        toast.error("Invalid UTR No.");
        return;
      }
      let responce = await API.post(
        `/upiFundRequest`,
        {
          uid: userId,
          amount: amount,
          txn: txn,
          bankid: bankName,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Submit API", responce);
      if (responce.data.data === "success") {
        toast.success("Request Successfully");

        setAmount("");
        setTxn("");
        setBankName("");
        setAdminBankDetail([]);
      } else {
        toast.error(responce.data.data);
      }
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          UpdateAuth({
            isAuth: false,
            userId: null,
            jwt_token: null,
          })
        );
      }
    }
  }
  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} />
      <Footer />
      <div className="col-lg-10">
        <div className="row">
          <div className="col-12 px-2 p-1">
            <div className="funds-transfer-main-div ">
              <div className="F-h p-2 pb-0">
                <p className="ms-3">Fund Request (UPI)</p>
              </div>

              <div className="form-area d-flex justify-content-center align-items-center">
                <div className="card deposit-card mg-lg-4 mt-2">
                  <div className="px-4">
                    <form onSubmit={handleSubmit}>
                      <div className="row mt-2">
                        <div className="col-md-6 px-1">
                          <div className="form-group">
                            <label className="" htmlFor="bankName">
                              Select UPI
                            </label>
                            <select
                              className="form-control mt-2 "
                              id="bankName"
                              value={bankName}
                              onChange={handleSelect}
                              required
                            >
                              <option disabled value="">
                                Select UPI
                              </option>
                              {bankDetails?.map((element) => (
                                <option key={element.id} value={element.id}>
                                  {element.btcname}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6 px-1">
                          <div className="form-group">
                            <label className="" htmlFor="accountNumber">
                              {" "}
                              Mobile Number
                            </label>
                            <input
                              type="text"
                              className="form-control mt-2 user-id-input"
                              id="accountNumber"
                              aria-describedby="accountNumber"
                              placeholder="Account Number"
                              value={AdminBankDetail[0]?.btcaddress || ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-md-12 px-1">
                          <div className="form-group">
                            <label className="" htmlFor="ifsc">
                              {" "}
                              UPI QR Code
                            </label>
                            <br/>
                            <img className="img" width="200px" height="200px" src={`${AdminBankDetail[0]?.imag}`}></img>                            
                          </div>
                        </div>
                      
                        <div className="col-md-6 px-1">
                          <div className="form-group">
                            <label htmlFor="amount">Request Amount</label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="amount"
                              placeholder="Request Amount"
                              value={amount}
                              onChange={(e) => handleAmount(e)}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6 px-1">
                          <div className="form-group">
                            <label htmlFor="txn">UTR No.</label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              id="txn"
                              placeholder="UTR No."
                              value={txn}
                              onChange={(e) => handleTxn(e)}
                              autoComplete="off"
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn deposit-f-btn mt-lg-4 mt-2  "
                      >
                        Request
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FundRequestUPI;