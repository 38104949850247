import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { API } from '../../API/Api';
import { UpdateAuth } from '../../Redux/AuthSlice';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import Table from '../Table/Table';
import TableButtons from '../Pagination/TableButtons';

export default function WeeklyBonus() {
    const [showSidebar, setShowSidebar] = useState(false);

    const toggleSidebar = () => {
      setShowSidebar(!showSidebar);
    };
    const handleLinkClick = () => {
      // Close the sidebar when a link is clicked
      setShowSidebar(false);
    };
  
    const [ReportApi, setReportApi] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);
    const [listPerpage, setlistPerpage] = useState(50);
    const [totalCount, settotalCount] = useState(50);
    const dispatch = useDispatch();
  
    const { userId, jwt_token } = useSelector((state) => state.Auth);
  
    const Report_API = async () => {
      try {
        let responce = await API.post(
          "weeklyBonusReport",
          {
            uid: userId,
            fdate: "",
            tdate: "",
          },
          {
            headers: {
              Authorization: `${jwt_token}`,
              "Content-Type": "application/json",
            },
          }
        );
        responce = responce.data.data[0];
        console.log("weeklyres-->", responce);
        setReportApi([]);
  
        let arr = [];
        responce.forEach((item, index) => {
          arr.push({
            sr: item.row,
            uid: item.uid,
            // fid: item.FromId,
            // address: item.f_name,
            amount: item.Onamount,
            income: item.income,
            remark: item.Remark,
            date: item.dd,
          });
        });
  
        setReportApi(arr);
      } catch (e) {
        if (
          e.response.data.showableMessage ===
          "Please login to access this resource"
        ) {
          dispatch(
            UpdateAuth({
              isAuth: false,
              userId: null,
              jwt_token: null,
            })
          );
        }
      }
    };
  
    useEffect(() => {
      Report_API();
    }, []);
  
    const indexOfLastPost = currentPage * listPerpage;
    const indexOfFirstPage = indexOfLastPost - listPerpage;
    const currentPost = ReportApi.slice(indexOfFirstPage, indexOfLastPost);
  
    var [reportData, set_reportData] = new useState({
      cols: [
        { Header: "S.No", accessor: "sr" },
        { Header: "User Id", accessor: "uid" },
        // { Header: "From Id", accessor: "fid" },
        // { Header: "From Name", accessor: "address" },
        // { Header: "On Amount", accessor: "amount" },
        { Header: "Income", accessor: "income" },
        { Header: "Remark", accessor: "remark" },
        { Header: "Date", accessor: "date" },
      ],
    });
  
    return (
      <>
        <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />
  
        <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} />
        <div className="col-lg-10 home mt-2">
          <div className="col-12">
            <div className="Heading fw-bold px-2 py-sm-4 py-2">
              Weekly Bonus
            </div>
          </div>
          <div className="container">
            <Table data={[...ReportApi]} columns={reportData.cols} />
            <br />
            <TableButtons
              indexOfFirstPage={indexOfFirstPage}
              indexOfLastPost={indexOfLastPost}
              setcurrentPage={setcurrentPage}
              currentPage={currentPage}
              totalData={totalCount}
              listPerpage={listPerpage}
              handleRowsPerPageChange={setlistPerpage}
            />
          </div>
        </div>
      </>
    );
}
