import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { UpdateAuth } from "../../Redux/AuthSlice";
import { API } from "../../API/Api";
import { useDispatch, useSelector } from "react-redux";
import Table from "../Table/Table";
import TableButtons from "../Pagination/TableButtons";

export default function ReceivedHistory() {
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };

  const [ReportApi, setReportApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const dispatch = useDispatch();

  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const Referral_API = async () => {
    try {
      let responce = await API.post(
        "/fundReceivedHistory",
        {
          uid: userId,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce.data.data[0];
      console.log("res referral-->", responce);
      setReportApi([]);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: index + 1,
          uid: item.uid,
          amount: item.amount,
          deduct: item.deduct,
          utrno: item.utrno,
          edate: item.edate,
          remark: item.remark,
        });
      });

      setReportApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          UpdateAuth({
            isAuth: false,
            userId: null,
            jwt_token: null,
          })
        );
      }
    }
  };

  useEffect(() => {
    Referral_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = ReportApi.slice(indexOfFirstPage, indexOfLastPost);

  var [reportData, set_reportData] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      { Header: "Amount", accessor: "amount" },
      { Header: "Charge", accessor: "deduct" },
      { Header: "UTR No.", accessor: "utrno" },
      { Header: "Date", accessor: "edate" },
      { Header: "Remark", accessor: "remark" },
    ],
  });

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} />
      <div className="col-lg-10 home mt-2">
        <div className="col-12">
          <div className="Heading fw-bold px-2 py-sm-4 py-2">
            Fund Received History
          </div>
        </div>
        <div className="container">
          <Table data={[...currentPost]} columns={reportData.cols} />
          <br />
          <TableButtons
            indexOfFirstPage={indexOfFirstPage}
            indexOfLastPost={indexOfLastPost}
            setcurrentPage={setcurrentPage}
            currentPage={currentPage}
            totalData={ReportApi.length}
            listPerpage={listPerpage}
            handleRowsPerPageChange={setlistPerpage}
          />
        </div>
      </div>
    </>
  );
}
