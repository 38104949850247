import React, { useEffect, useState } from 'react'
import { UpdateAuth } from '../../Redux/AuthSlice';
import { API } from '../../API/Api';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import Table from '../Table/Table';
import TableButtons from '../Pagination/TableButtons';

export default function MyDownline() {

    const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };


  const [ReportApi, setReportApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const [status, setstatus] = useState(2);

  const dispatch = useDispatch();

  const { userId, jwt_token } = useSelector((state) => state.Auth);


  // console.log("level Status11", level, status);

  const Report_API = async () => {
    try {
    //   console.log("level Status", level, status);

      let responce = await API.post(
        "myDownline",
        {
          uid: userId,
          fdate: "",
          tdate: "",
          status: status,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json", 
          },
        }
      );
      responce = responce.data.data[0];
      console.log("res-->", responce);
      setReportApi([]);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item.row,
          uid: item.uid,
          walletAddress: item.f_name,
        //   level: item.Leveltype,
          amount: item.packageamount,
          dd: item.activationDate,
          Registered: item.registrationDate,
          status: item.idType,
        });
      });

      setReportApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          UpdateAuth({
            isAuth: false,
            userId: null,
            jwt_token: null,
            
          })
        );
      }
    }
  };

  useEffect(() => {
    Report_API();
  }, [ status]);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = ReportApi.slice(indexOfFirstPage, indexOfLastPost);

  var [reportData, set_reportData] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      { Header: "Name", accessor: "walletAddress" },
    //   { Header: "Level", accessor: "level" },
      { Header: "Registration Date", accessor: "Registered" },
      // { Header: "Id Type", accessor: "status" },
      // { Header: "Package Amount", accessor: "amount" },
      // { Header: "Activation Date", accessor: "dd" },
    ],
  });

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} />
      <div className="col-lg-10 home mt-2">
        <div className="col-12">
          <div className="Heading fw-bold px-2 py-sm-4 py-2">
           My Downline
          </div>
        </div>
        <div className="container">
          <Table data={[...ReportApi]} columns={reportData.cols} />
          <br />
          <TableButtons
            indexOfFirstPage={indexOfFirstPage}
            indexOfLastPost={indexOfLastPost}
            setcurrentPage={setcurrentPage}
            currentPage={currentPage}
            totalData={totalCount}
            listPerpage={listPerpage}
            handleRowsPerPageChange={setlistPerpage}
          />
        </div>
      </div>
    </>
  )
}
