import React, { useState } from "react";
import ScoreCard from "./ScoreCard";
import "./Sport.css";
import OpenMarkeet from "./OpenMarkeet";
import LiveTvSection from "./LiveTvSection";
import MatchOdds from "./MatchOdds";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";

const Sport = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} />
      <Footer />
      <div className="col-lg-10  pt-1  ">
        <div className="row px-1">
          <div className="col-lg-8  px-2 score-card">
            <ScoreCard />
            <OpenMarkeet />
            {/* <MatchOdds/> */}
          </div>
          <div className="col-4">
            <LiveTvSection />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sport;
