import React, { useEffect, useState } from "react";
import MatchesDetail from "../MatchesDetail/MatchesDetail";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import "./Home.css";
import Footer from "../Footer/Footer";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import { SetDashboardData, UpdateAuth } from "../../Redux/AuthSlice";
import { API } from "../../API/Api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ColorGame from "../ColorGame/ColorGame";

const Home = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const { userId, jwt_token } = useSelector((state) => state.Auth);
  // console.log("userid", userId);
  // console.log("jwt_token", jwt_token);

  

  const Dashboard_API = async () => {
    try {
      let res = await API.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });

      console.log("Dashboard_API", res);

      const responseData = res.data.data[0][0];
      console.log("dashdata", responseData);
      setData(responseData);
      
      dispatch(SetDashboardData(responseData));
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
        navigate("/");
      }
    }
  };
  

  useEffect(() => {
    Dashboard_API();
  }, []);

  return (
    <>
      {/* <ColorGame /> */}
      <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} />
      <Footer />
      <div className="col-lg-10 home">
        <div className="row home p">
          <MatchesDetail  />
          <RightSidebar   />
        </div>
      </div>
    </>
  );
};

export default Home;
