import React, { useState, useEffect } from "react";
import SliderH from "./SliderH";
import "./MatchesDetail.css";
// import { FaPlay } from "react-icons/fa";
// import cricket from "../../Media/5.png";
// import { BsFillPersonFill } from "react-icons/bs";
// import tennis from "../../Media/4.png";
// import football from "../../Media/6.png";
// import MatchData from "./MatchData";
// import InPlayMatchData from "./InPlayMatchData";
// import aircraft from "../../Media/output-onlinepngtools (5).png";
import colorimg from "../../Media/output-onlinepngtools (8).png";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TodayBusiness from "../../Media/profile/TodayBusiness.svg";
import network from "../../Media/profile/NetIncome.svg";

const MatchesDetail = () => {
  const [active, setActive] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const dispatch = useDispatch();
  // const { data, loading, error } = useSelector((state) => state.api);
  // console.log(data ,"data of api");

  // useEffect(() => {
  //   dispatch(fetchData());
  // }, [dispatch]);

  useEffect(() => {
    // Toggle the visibility of the image every second
    const interval = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 500);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const dashboardData = useSelector((state) => state.Auth.dashboardData);
  const {
    SignupBonus,
    SelfBonus,
    LevelBonus,
    DailySalary,
    WeeklyBonus,
    Net_DepositeFund,
    Used_DepositeFund,
    Total_DepositeFund,
    TotalIncome,
    Net_Balance,
    Used_Balance,
    Total_5XIncome,
    Total_BettingA,
    Total_Caping,
    Total_Remain,
  } = dashboardData || {};

  return (
    <div className=" col-lg-9 p-2 center-div">
      <SliderH />
      <div className="MainArea ">
        <div className="sport-div bg-dark row">
          {/* <div
            onClick={() => {
              setActive(true);
            }}
            className={`single-sport-div ${active && "is-active"}`}
          >
            <div className="row">
              <div className="col-10 d-flex flex-column justify-content-end align-items-center ">
                <div
                  className={`round-sport-div d-flex justify-content-center align-items-center ms-1 mt-2 ${
                    active && "is-activ"
                  }`}
                >
                  <img
                    className=" sport-img ms-1"
                    src={cricket}
                    alt="cricket"
                  />
                </div>
                <p className={`ms-1 sport-name mt-1 ${active && "is-act"}`}>
                  CRICKET
                </p>
              </div>
              <div className="col-2 ">
                <div className="num-div d-flex justify-content-center ">13</div>
              </div>
            </div>
          </div> */}
          {/* <div className="single-sport-div">
            <div className="row">
              <div className="col-10 d-flex flex-column justify-content-end align-items-center ">
                <div className=" round-sport-div d-flex justify-content-center align-items-center  mt-2">
                  <img className=" sport-img " src={football} alt="cricket" />
                </div>
                <p className="ms-1 sport-name mt-1">SOCCER</p>
              </div>
              <div className="col-2">
                <div className="num-div d-flex justify-content-center">13</div>
              </div>
            </div>
          </div> */}
          {/* <div className="single-sport-div">
            <div className="row">
              <div className="col-10 d-flex flex-column justify-content-center align-items-center ">
                <div className=" round-sport-div d-flex justify-content-center align-items-center ms-1 mt-2">
                  <img className=" sport-img ms-1" src={tennis} alt="cricket" />
                </div>
                <p className="ms-1 mt-1 sport-name">TENNIS</p>
              </div>
              <div className="col-2">
                <div className="num-div d-flex justify-content-center align-items-center">
                  13
                </div>
              </div>
            </div>
          </div> */}

          <div className="single-sport-div ms-4">
            <NavLink to="/ColorGame" className="nav-link">
              <div className="blink-img-div">
                {isVisible && (
                  <img
                    className="image-size img-fluid "
                    src={colorimg}
                    alt="a"
                  />
                )}
              </div>
              <p className="ms-1  sport-name">COLOUR</p>
            </NavLink>
          </div>
          {/* <div className="single-sport-div ms-1">
            <NavLink to="/Aviator" className="nav-link">
              <div className="image-div">
                {isVisible && (
                  <img
                    className=" a-image-size img-fluid mt-2"
                    src={aircraft}
                    alt="a"
                  />
                )}
              </div>
              <p className="ms-1 mt-1 sport-name mt-3">AVIATOR</p>
            </NavLink>
          </div> */}

          {/* <----------------------MatchData-----------------------> */}
          {/* <MatchData />

          <InPlayMatchData />
          <MatchData />
          <MatchData />
          <MatchData /> */}
        </div>

        {/* ********************************* MLM BOX **************************** */}
        <br />
        <div className="row g-3 mx-0 my-3 samllcardsection">
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            {/* <a href="/MatrixDownline"> */}
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={TodayBusiness}
                        alt="TodayBusiness"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Total Deposit Fund</div>
                    <div className="sub_heading">{Total_DepositeFund}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* </a> */}
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            {/* <a href="/AutopoolIncome"> */}
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={TodayBusiness}
                        alt="TodayBusiness"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Used Deposite Fund</div>
                    <div className="sub_heading">{Used_DepositeFund}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* </a> */}
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            {/* <a href="/stakingGlobalteam"> */}
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={TodayBusiness}
                        alt="TodayBusiness"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Wallet Balance</div>
                    <div className="sub_heading">{Net_DepositeFund}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* </a> */}
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            {/* <a href="/StakingLevelBonus"> */}
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={TodayBusiness}
                        alt="TodayBusiness"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">SignUp Bonus</div>
                    <div className="sub_heading">{SelfBonus}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* </a> */}
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            {/* <a href="/DirectIncomeStaking"> */}
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={TodayBusiness}
                        alt="TodayBusiness"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Referral Bonus</div>
                    <div className="sub_heading">{SignupBonus}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* </a> */}
          </div>
        
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            {/* <a href="/ReturnIncome"> */}
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={TodayBusiness}
                        alt="TodayBusiness"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Level Bonus</div>
                    <div className="sub_heading">{LevelBonus}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* </a> */}
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            {/* <a href="/MatrixIncome"> */}
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={TodayBusiness}
                        alt="TodayBusiness"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Daily Salary</div>
                    <div className="sub_heading">{DailySalary}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* </a> */}
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={network}
                        alt="TotalIncome"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Weekly Bonus</div>
                    <div className="sub_heading">{WeeklyBonus}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          {/* <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={network}
                        alt="totalw"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Total Bonus</div>
                    <div className="sub_heading">0</div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            {/* <a href="/withdrawal"> */}
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={network}
                        alt="NetIncome"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Total Reward Wallet</div>
                    <div className="sub_heading">{TotalIncome}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* </a> */}
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={network}
                        alt="NetIncome"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Used Reward Wallet</div>
                    <div className="sub_heading">{Used_Balance}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={network}
                        alt="NetIncome"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Balance Reward Wallet</div>
                    <div className="sub_heading">{Net_Balance}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            {/* <a href="/AutopoolIncome"> */}
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={TodayBusiness}
                        alt="TodayBusiness"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Income Limit</div>
                    <div className="sub_heading">{Total_Caping}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* </a> */}
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            {/* <a href="/AutopoolIncome"> */}
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={TodayBusiness}
                        alt="TodayBusiness"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Earned 5X Income</div>
                    <div className="sub_heading">{Total_5XIncome}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* </a> */}
          </div>
          <div className="col-xl-4 col-12 col-sm-6 col-md-4">
            {/* <a href="/AutopoolIncome"> */}
            <div className="card">
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-3 px-0">
                    <div className="cardimage">
                      <img
                        className="w-75 h-75 imgcolor"
                        src={TodayBusiness}
                        alt="TodayBusiness"
                      />
                    </div>
                  </div>
                  <div className="col-9 px-0">
                    <div className="card_heading">Remain Income</div>
                    <div className="sub_heading">{Total_Remain}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* </a> */}
          </div>
        </div>

        {/* ***************** MLM BOX END*************** */}
      </div>
    </div>
  );
};

export default MatchesDetail;
