import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuth: false,
  userId: "",
  jwt_token: "",
  dashboardData: null, 
};

export const AuthSlice = createSlice({
  name: "UserAuth",
  initialState,
  reducers: {
    UpdateAuth: (state, action) => {
      const { isAuth, userId, jwt_token } = action.payload;
      state.isAuth = isAuth;
      state.userId = userId;
      state.jwt_token = jwt_token;
    },
    SetDashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
  },
});

export const { UpdateAuth, SetDashboardData } = AuthSlice.actions;
export default AuthSlice.reducer;
