import { useEffect, useState } from "react";
import { API } from "../../API/Api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { SetDashboardData, UpdateAuth } from "../../Redux/AuthSlice";

const ShowModal = ({ open, data, onClose }) => {
  const dispatch = useDispatch();
  const [remainingTime, setRemainingTime] = useState(20);
  const [stakeValue, setStakeValue] = useState(0);
  const [selectedWallet, setSelectedWallet] = useState("");
  const [dashdata, setData] = useState({});

  const { userId, jwt_token } = useSelector((state) => state.Auth);
  const { Net_Balance, Net_DepositeFund, exp_amount } = dashdata;

  const { value, id, color, btn } = data;
  const ButtonArr = [
    {
      label: "10",
      value: "10",
    },
    {
      label: "20",
      value: "20",
    },
    {
      label: "50",
      value: "50",
    },
    {
      label: "100",
      value: "100",
    },
    {
      label: "1k",
      value: "1000",
    },
    {
      label: "2k",
      value: "2000",
    },
    {
      label: "3k",
      value: "3000",
    },
    {
      label: "5k",
      value: "5000",
    },
    {
      label: "10k",
      value: "10000",
    },
    {
      label: "30k",
      value: "30000",
    },
  ];
  const handleStakeChange = (value) => {
    setStakeValue(value);
  };

  const handleWalletChange = (event) => {
    setSelectedWallet(event.target.value);
  };

  const handleSaveChanges = async () => {
    if (id) {
      try {
        if (stakeValue <= 0) {
          toast.error("Invalid Amount");
          return;
        }
        let res = await API.post(
          "placebetcolorball",
          {
            roundid: id,
            uid: userId,
            ball: value,
            color: color,
            stake: stakeValue,
            wallet_type: selectedWallet,
          },
          {
            headers: {
              Authorization: `${jwt_token}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("res", res.data);
        if (res.data.data === "success") {
          toast.success(res.data.data);
        } else {
          toast.error(res.data.data);
        }
      } catch (e) {
        console.log("Color Play ", e);
      } finally {
        onClose();
        setStakeValue(0);
      }
    }
  };

  useEffect(() => {
    if (remainingTime === 0) {
      onClose();
    }
  }, [remainingTime]);

  useEffect(() => {
    let timer;
    if (remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [remainingTime]);

  const Dashboard_API = async () => {
    try {
      let res = await API.get(`userDashboard?uid=${userId}`, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });

      console.log("Dashboard_API", res);

      const responseData = res.data.data[0][0];

      setData(responseData);

      dispatch(SetDashboardData(responseData));
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(UpdateAuth({ isAuth: false, userId: null, jwt_token: null }));
      }
    }
  };

  useEffect(() => {
    Dashboard_API();
  }, []);
  return (
    <>
      <div
        onClick={() => {
          onClose(false);
        }}
        className="color-modal-wrapper"
      ></div>
      <div className="color-modal-container card">
        <div className="model-h-dive  d-flex justify-content-between ">
          <div className="px-2 p-1">{btn == true ? color : value}</div>
          <div className="px-2 p-1">Time Left {remainingTime}s</div>
        </div>

        <div className="innercontent ">
          <div className="borderintop ">
            <div className="">
              <div className="maindis">
                <h6 className="text-white">
                  Main Wallet : <span>{Net_DepositeFund}</span>
                </h6>
                <h6 className="text-white">
                  Reward Wallet : <span>{Net_Balance}</span>
                </h6>
                <h6 className="text-white">
                  Exp : <span>{exp_amount}</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="innerheading px-2 py-2 text-white">
            <div className="maindis">
              <h6 className="text-white">You Choose</h6>
              <h6 className="text-white"> {btn == true ? color : value}</h6>
            </div>
          </div>

          <div className="row mx-0 align-items-center">
            <div className="col-3">
              <div className="paymettext1 text-white fw-bold py-3">
                Stake (INR)
              </div>
            </div>
            <div className="col-9 ">
              <input type="text" value={stakeValue} style={{ width: "70%" }} />
            </div>

            <div className="col-3">
              <div className="paymettext1 text-white fw-bold py-3">
                Wallet Type
              </div>
            </div>
            <div className="col-9 ">
              <div className="maindiss">
                <div className="flex">
                  <input
                    type="radio"
                    className="t-2"
                    id="mainWallet"
                    value="MainWallet"
                    checked={selectedWallet === "MainWallet"}
                    onChange={handleWalletChange}
                  />
                  &nbsp;
                  <label className="labels" htmlFor="mainWallet">
                    Main Wallet (100%)
                  </label>
                </div>
              </div>
              <input
                type="radio"
                id="BothWallet"
                value="BothWallet"
                checked={selectedWallet === "BothWallet"}
                onChange={handleWalletChange}
              />
              &nbsp;
              <label className="labels" htmlFor="BothWallet">
                Main Wallet(80%) & Reward Wallet(20%)
              </label>
            </div>
            <div className="col-12 px-0">
              {/* <ul className="modallist d-flex align-items-center  ps-0  py-3">
                {ButtonArr.map((btn) => (
                  <li
                    className={`border rounded-2 px-2 py-2 text-white  ${
                      stakeValue === btn.value && "is-activated"
                    } `}
                    key={btn.label}
                    value={btn.value}
                    onClick={(e) => handleStakeChange(e.target.value)}
                  >
                    {btn.label}
                  </li>
                ))}
              </ul> */}

              <div className="modallist d-flex align-items-center  ps-0 justify-content-center py-2">
                {ButtonArr.map((btn) => (
                  <button
                    className={`num-box d-flex justify-content-center align-items-center ${
                      stakeValue === btn.value && "is-activated"
                    } `}
                    key={btn.label}
                    value={btn.value}
                    onClick={(e) => handleStakeChange(e.target.value)}
                  >
                    {btn.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center modallist mt-1 ">
            <button
              onClick={() => {
                onClose(false);
              }}
              className="red-button"
            >
              Close
            </button>
            <button
              className="Green-button"
              onClick={() => handleSaveChanges()}
            >
              Place Bets
            </button>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};
export default ShowModal;
