import React, { useState } from "react";
import "./ChangePassword.css";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import { Button, Form, Input } from "antd";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { API } from "../../API/Api";



const ChangePassword = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };
  const { userId, jwt_token } = useSelector((state) => state.Auth);


  const onFinish = async (values) => {
    // console.log("Success:", values);
  
      let res = await API.post("/changePassword", {
        uid: userId,
        // otp: values.otp,
        oldPassword: values.oldpassword,
        newPassword: values.password,
      }, {
        headers: {
          Authorization: `${jwt_token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("ResUpdated", res);
      if (res.data.data =="Successfully Updated") {
        toast.success(res.data.data);
        // setotp_flag(false);
      } else {
        toast.error(res.data.data);
      }
    //  else {
    //   let res = await API.post("/passwordVerification", {
    //     uid: user,
    //   });
    //   // console.log("res", res);
    //   if (res.data.success == true) {
    //     setotp_flag(true);
    //     toast.success(
    //       "Email with verification code has been sent to you Successfully"
    //     );
    //   }
    // }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} />
      <Footer />
      <div className="col-lg-10">
        <div className="row">
          <div className="col-12 p-1 px-2">
            <div className="funds-transfer-main-div ">
              <div className="F-h p-2 pb-0">
                <p className="ms-3">Change Password</p>
              </div>

              <div className="form-area d-flex justify-content-center align-items-center">
                <div className="card cp-card mt-4">
                  <div className="px-4">
                    {/* <form>
                      <div className="form-group mt-2">
                        <label for="exampleInputPassword1">
                          Current Password*
                        </label>
                        <input
                          type="password"
                          className="form-control mt-2"
                          id="exampleInputPassword1"
                          placeholder="Password"
                        />
                      </div>
                      <div className="form-group mt-2">
                        <label for="exampleInputPassword1">New Password</label>
                        <input
                          type="password"
                          className="form-control mt-2"
                          id="exampleInputPassword1"
                          placeholder="Password"
                        />
                      </div>
                      <div className="form-group mt-2">
                        <label for="exampleInputPassword1">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          className="form-control mt-2"
                          id="exampleInputPassword1"
                          placeholder="Password"
                        />
                      </div>

                      <button type="submit" className="btn transfer-btn mt-4">
                        Submit
                      </button>
                    </form> */}
                    <Form
                          name="basic"
                          layout={"vertical"}
                          initialValues={{
                            remember: true,
                          }}
                          style={{ width: "100%" }}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                        >
                          <Form.Item
                            name="oldpassword"
                            label="Old Password"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Old password!",
                              },
                            ]}
                          >
                            <Input.Password />
                          </Form.Item>
                          <Form.Item
                            name="password"
                            label="New Password"
                            rules={[
                              {
                                required: true,
                                message: "Please input your New password!",
                              },
                            ]}
                            hasFeedback
                          >
                            <Input.Password />
                          </Form.Item>
                          {/* <Input.Password placeholder=" Enter Old Password" /> */}
                          <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            dependencies={["password"]}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: "Please confirm your password!",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue("password") === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "The new password that you entered do not match!"
                                    )
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password />
                          </Form.Item>
                          {/* <Form.Item
                            // style={{ display: otp_flag ? "" : "none" }}
                            name="otp"
                            label="OTP"
                            rules={[
                              {
                                message: "Please input your OTP!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item> */}

                          <Form.Item
                            wrapperCol={{
                              offset: 8,
                              span: 16,
                            }}
                          >
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                          </Form.Item>
                        </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
