import React, { useEffect, useState } from "react";
import { API } from "../../API/Api";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAuth } from "../../Redux/AuthSlice";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Table from "../Table/Table";
import TableButtons from "../Pagination/TableButtons";

export default function LevelBonus() {
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };

  const [ReportApi, setReportApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const [level, setlevel] = useState(0);

  const dispatch = useDispatch();

  const { userId, jwt_token } = useSelector((state) => state.Auth);

  const Report_API = async () => {
    try {
      let responce = await API.post(
        "teamBettingBonus",
        {
          uid: userId,
          fdate: "",
          tdate: "",
          level: level,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      responce = responce.data.data[0];
      console.log("FRefferalfres-->", responce);
      setReportApi([]);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item.row,
          uid: item.sid,
          fid: item.uid,
          address: item.f_name,
          lvl: item.lvl,
          amount: item.onamount,
          income: item.income,
          remark: item.remark,
          date: item.dd,
          rate: item.onrate,
        });
      });

      setReportApi(arr);
    } catch (e) {
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          UpdateAuth({
            isAuth: false,
            userId: null,
            jwt_token: null,
          })
        );
      }
    }
  };

  useEffect(() => {
    Report_API();
  }, [level]);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = ReportApi.slice(indexOfFirstPage, indexOfLastPost);

  var [reportData, set_reportData] = useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      { Header: "From Id", accessor: "fid" },
      { Header: "From Name", accessor: "address" },
      { Header: "On Amount", accessor: "amount" },
      { Header: "On Rate", accessor: "rate" },
      { Header: "Level", accessor: "lvl" },
      { Header: "Income", accessor: "income" },
      { Header: "Remark", accessor: "remark" },
      { Header: "Date", accessor: "date" },
    ],
  });

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} />
      <div className="col-lg-10 home mt-2">
        <div className="col-12">
          <div className="Heading fw-bold px-2 py-sm-4 py-2">Level Bonus</div>
        </div>
        <div className="col-md-3 col-sm-6 col-12 m-2">
          <div className="fs-6 text-white">Level Choose</div>
          <div className="form-control border-0 text-white bg-dark shadow-none">
            <select
              onChange={(e) => {
                setlevel(e.target.value);
              }}
            >
              <option value={"0"}>All</option>
              <option value={"1"}>Level 1</option>
              <option value={"2"}>Level 2</option>
              <option value={"3"}>Level 3</option>
              <option value={"4"}>Level 4</option>
              <option value={"5"}>Level 5</option>
              <option value={"6"}>Level 6</option>
              <option value={"7"}>Level 7</option>
              <option value={"8"}>Level 8</option>
              <option value={"9"}>Level 9</option>
              <option value={"10"}>Level 10</option>
            </select>
          </div>
        </div>
        <br />
        <div className="container">
          <Table data={[...ReportApi]} columns={reportData.cols} />
          <br />
          <TableButtons
            indexOfFirstPage={indexOfFirstPage}
            indexOfLastPost={indexOfLastPost}
            setcurrentPage={setcurrentPage}
            currentPage={currentPage}
            totalData={totalCount}
            listPerpage={listPerpage}
            handleRowsPerPageChange={setlistPerpage}
          />
        </div>
      </div>
    </>
  );
}
